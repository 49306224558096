import React from 'react';
import _ from 'lodash';
import { showInfo } from 'utils';
import { INFO, ERROR, FLOAT_P_2 } from 'constants';
import { PureInput as Input, Select, TextArea, DateTimePicker, PopUp, ShowInfo } from 'components';
import { addFee } from 'operation/listOperation/feeOp';
import AssistBox from 'operation/listOperation/AccountingOperat/AssistBox';
import { customerProjectSug, customerExtHeader } from 'utils/sug/customer';
import lineSug, { lineExtHeader } from 'utils/sug/line';
import expenseSug, { expenseSugHeader } from 'utils/sug/expenseSug';
import truckSug from 'utils/sug/truck';
import userSug from 'utils/sug/user';
import oilCardSug, { rechargeOilCardHeader } from 'utils/sug/oilCard';
import payeeSug, { payeeSugHeader } from 'utils/sug/payeeSug';
import carBatch from 'utils/sug/carBatch';
import driver, { driverExtHeader } from 'utils/sug/driver';
import repayNoSug from 'utils/sug/repayNo';
// import baseDataSug from 'utils/sug/baseData'
import projectSug, { projectExtHeader } from 'utils/sug/project';
import orderNumSug from 'utils/sug/orderNumSug';

import { formSet } from './tool';

const addExpense = (page, index) => {
  const pageType = 'expense';
  const curProp = { props: { showKey: 'fee_name', uniqueKey: 'id' } };
  const defaultValues = {
    fee_direct: pageType === 'expense' ? 'out' : 'inCome',
  };
  const opCallBack = res => {
    console.log('opCallBack', res);
  };
  addFee(undefined, pageType, defaultValues, opCallBack);
};

const handleBelong = (index, page) => {
  const { preParam, data = [] } = page.state;
  const { expense } = data[index];
  if (!data.length || !expense) {
    return showInfo(ERROR, '请选择费用类别');
  }
  if (!expense.assist || expense.assist.length === 0) {
    return showInfo(ERROR, '该费用类别没有费用归属');
  }
  const { assist } = expense;
  const assistData = assist.filter(x => !Number.isNaN(parseInt(x, 10)));
  const companyId = preParam.com_id ? preParam.com_id.id : +window.company_id;
  const belongAssist = {};
  const belongStr = [];
  if (assistData.length > 0) {
    new PopUp(ShowInfo, {
      type: INFO,
      title: '辅助核算',
      closable: false,
      showIcon: false,
      maskClosable: false,
      content: React.createElement(AssistBox, {
        company_id: companyId,
        assistArray: assistData,
        handleSelect: resData => {
          const { entries } = Object;
          for (const [key, value] of entries(resData)) {
            if (value !== 0) {
              belongStr.push(value.name);
              belongAssist[key] = value.id;
            }
          }
        },
      }),
      buttons: [
        {
          name: '确定',
          btnType: 'primary',
          cb: thisDialog => {
            formSet(index, 'belong_new', { str: belongStr.join('_'), val: belongAssist }, page);
            thisDialog.handleHide();
          },
        },
        {
          name: '取消',
          btnType: 'default',
          cb: thisDialog => {
            thisDialog.handleHide();
          },
        },
      ],
    }).show();
  }
};

export const renderTbodyTd = (page, row = {}, index) => {
  const { preParam = {}, header = [] } = page.state;

  // 发生网点id
  const _comId = preParam.com_id ? preParam.com_id.id : +window.company_id;
  // 部门ID
  const useDepartment = preParam.department ? preParam.department.department_id : '';

  const isConfigProjectBaseData = _.get(window.company_setting, 'proj_data_source.value');
  // const sugProject = () => (keyword, { fetch }) => baseDataSug({ fetch, keyword, key: isConfigProjectBaseData, searchKey: 'proj_name', company_id: _comId })
  const sugProject =
    () =>
    (keyword, { fetch }) =>
      projectSug({ fetch, keyword, cid: _comId });
  const sugLine =
    () =>
    (keyword, { fetch }) =>
      lineSug({ fetch, keyword, cid: _comId, line_type: 1 });
  const sugCustomer =
    () =>
    (keyword, { fetch }) =>
      customerProjectSug({ fetch, keyword, cid: _comId });
  const sugTruck =
    loan_type =>
    (keyword, { fetch }) =>
      truckSug({
        fetch,
        keyword,
        is_tr_header: 1,
        cid: _comId,
        need_remainder: true,
        state: [0, 1],
        route: undefined,
        loan_type,
      });
  const sugDriverName =
    loan_type =>
    (keyword, { fetch }) =>
      driver({ fetch, keyword, is_oil_card: true, cid: _comId, need_remainder: true, loan_type });
  const sugCarBatch =
    () =>
    (keyword, { fetch }) =>
      carBatch({ fetch, keyword, cid: _comId, tab: 'loan_batch_suggest' });
  const sugPayee =
    () =>
    (keyword, { fetch }) =>
      payeeSug({ fetch, keyword, cid: _comId });
  const sugUser =
    () =>
    (keyword, { fetch }) =>
      userSug({ fetch, keyword, cid: _comId, need_remainder: true });
  const sugExpense =
    () =>
    (keyword, { fetch }) =>
      expenseSug({ fetch, keyword, cid: _comId, feeDirect: 'out', useDepartment });
  const sugOrderNum =
    () =>
    (keyword, { fetch }) =>
      orderNumSug({ fetch, keyword, cid: _comId });
  const sugOilCard =
    () =>
    (keyword, { fetch }) =>
      oilCardSug({ fetch, keyword, cid: _comId });
  let sugRepayNoTab;
  let repayNoHeader;
  switch (preParam.billType) {
    case 'repay_car':
      sugRepayNoTab = 'loan_car_bill_detail';
      repayNoHeader = [
        { key: 'bill_no', title: '借款单号' },
        { key: 'project_name', title: '项目客户' },
        { key: 'truck_num', title: '车牌号' },
        { key: 'balance', title: '车辆余额' },
        { key: 'still_amount', title: '待还金额' },
      ];
      break;
    case 'repay_driver':
      sugRepayNoTab = 'loan_driver_bill_detail';
      repayNoHeader = [
        { key: 'bill_no', title: '借款单号' },
        { key: 'project_name', title: '项目客户' },
        { key: 'driver_name', title: '司机姓名' },
        { key: 'balance', title: '司机余额' },
        { key: 'still_amount', title: '待还金额' },
      ];
      break;
    case 'repay_employee':
      sugRepayNoTab = 'loan_employee_bill_detail';
      repayNoHeader = [
        { key: 'bill_no', title: '借款单号' },
        { key: 'employee_name', title: '员工姓名' },
        { key: 'balance', title: '员工余额' },
        { key: 'still_amount', title: '待还金额' },
      ];
      break;
    default:
      sugRepayNoTab = '';
  }
  const sugRepayNo =
    () =>
    (keyword, { fetch }) =>
      repayNoSug({ fetch, keyword, cid: _comId, tab: sugRepayNoTab });

  return Object.keys(header).map((k, i) => {
    let _el = null;
    const _key = `${index}_${i}`;
    const item = header[k];
    const rowDisabled = row.otherProps && row.otherProps.disable ? row.otherProps.disable : [];
    const curDisabled = rowDisabled.length ? rowDisabled.includes(k) : item.disables || false;
    const curRequired = item.required || false;
    switch (k) {
      case 'loan_type':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              data={page.state.loan_type_list || []}
            />
          </td>
        );
        break;
      case 'abstract':
        _el = (
          <td key={_key}>
            <TextArea
              autoTrim={false}
              defaultValue={row[k]}
              disabled={curDisabled}
              onChange={e => formSet(index, k, e.target.value, page)}
              placeholder="请填写摘要，最多支持输入512个字符"
              className="abstract"
              title={row[k]}
            />
          </td>
        );
        break;
      case 'predict_refund_time':
        _el = (
          <td key={_key}>
            <DateTimePicker
              showTime
              classname="date-input"
              defaultValue={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
            />
          </td>
        );
        break;
      case 'handle_pay_amount':
        _el = (
          <td key={_key}>
            <Input
              value={row[k]}
              pattern={FLOAT_P_2}
              disabled={curDisabled}
              required={curRequired}
              data-path="handle_pay_amount"
              onChange={e => formSet(index, k, e.target.value, page)}
            />
          </td>
        );
        break;
      case 'car_remain_amount':
        _el = (
          <td key={_key}>
            <Input
              value={row[k] === 0 ? `${row[k]}` : row[k]}
              disabled={curDisabled}
              required={curRequired}
              data-path="car_remain_amount"
              onChange={e => formSet(index, k, e.target.value, page)}
            />
          </td>
        );
        break;
      case 'car_total_balance':
      case 'driver_total_balance':
      case 'employee_total_balance':
        _el = (
          <td key={_key}>
            <Input
              value={row[k] === 0 ? `${row[k]}` : row[k]}
              disabled={curDisabled}
              required={curRequired}
              data-path={k}
              onChange={e => formSet(index, k, e.target.value, page)}
            />
          </td>
        );
        break;
      case 'real_pay_amount':
        _el = (
          <td key={_key}>
            <Input
              value={row[k]}
              pattern={FLOAT_P_2}
              disabled={curDisabled}
              required={curRequired}
              data-path="real_pay_amount"
              onChange={e => formSet(index, k, e.target.value, page)}
            />
          </td>
        );
        break;
      case 'pre_pay_amount':
        _el = (
          <td key={_key}>
            <Input
              value={row[k]}
              pattern={FLOAT_P_2}
              disabled={curDisabled}
              required={curRequired}
              data-path="pre_pay_amount"
              onChange={e => formSet(index, k, e.target.value, page)}
            />
          </td>
        );
        break;
      case 'tip_amount':
        _el = (
          <td key={_key}>
            <Input
              value={row[k]}
              pattern={FLOAT_P_2}
              disabled={curDisabled}
              required={curRequired}
              data-path="tip_amount"
              onChange={e => formSet(index, k, e.target.value, page)}
            />
          </td>
        );
        break;
      case 'pre_pay_fuel':
        _el = (
          <td key={_key}>
            <Input
              value={row[k]}
              pattern={FLOAT_P_2}
              disabled={curDisabled}
              required={curRequired}
              data-path="pre_pay_fuel"
              onChange={e => formSet(index, k, e.target.value, page)}
            />
          </td>
        );
        break;
      case 'pay_total_amount':
        _el = (
          <td key={_key}>
            <Input
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              data-path="pay_total_amount"
              onChange={e => formSet(index, k, e.target.value, page)}
            />
          </td>
        );
        break;
      case 'driver_remain_amount':
        _el = (
          <td key={_key}>
            <Input
              value={row[k] === 0 ? `${row[k]}` : row[k]}
              disabled={curDisabled}
              required={curRequired}
              data-path="driver_remain_amount"
              onChange={e => formSet(index, k, e.target.value, page)}
            />
          </td>
        );
        break;
      case 'employee_remain_amount':
        _el = (
          <td key={_key}>
            <Input
              value={row[k] === 0 ? `${row[k]}` : row[k]}
              disabled={curDisabled}
              required={curRequired}
              data-path="employee_remain_amount"
              onChange={e => formSet(index, k, e.target.value, page)}
            />
          </td>
        );
        break;
      case 'order_num':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              filter={sugOrderNum()}
              header={['order_num']}
              format="order_num"
              map={x => ({ od_id: x.od_id, order_num: x.order_num })}
            />
          </td>
        );
        break;
      case 'truck_num':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              filter={sugTruck(row?.loan_type || 'cash')}
              header={['tr_num']}
              format="tr_num"
              map={x => ({ ...x })}
            />
          </td>
        );
        break;
      case 'driver_name':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              filter={sugDriverName(row?.loan_type || 'cash')}
              header={driverExtHeader}
              format="name"
              map={x => ({ ...x })}
            />
          </td>
        );
        break;
      case 'batch_num':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              filter={sugCarBatch()}
              header={['car_batch']}
              format="car_batch"
              map={x => ({ ...x })}
            />
          </td>
        );
        break;
      case 'project_id':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              filter={sugProject()}
              header={projectExtHeader}
              format="name"
              map={x => ({ ...x })}
            />
          </td>
        );
        break;
      case 'customer_id':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              filter={sugCustomer()}
              header={customerExtHeader}
              format="proj_name"
              map={x => ({ ...x })}
            />
          </td>
        );
        break;
      case 'line_id':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              filter={sugLine()}
              header={lineExtHeader}
              format="line_name"
              map={x => ({ ...x })}
            />
          </td>
        );
        break;
      case 'receipt_person':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              filter={sugPayee()}
              header={payeeSugHeader}
              format="tr_payee_name"
              map={x => ({
                id: x.id,
                tr_payee_name: x.tr_payee_name,
                tr_payee_open_bank: x.tr_payee_open_bank,
                tr_payee_bank_card_num: x.tr_payee_bank_card_num,
              })}
            />
          </td>
        );
        break;
      case 'receipt_person_bank':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              filter={sugPayee()}
              header={payeeSugHeader}
              format="tr_payee_open_bank"
              map={x => ({
                id: x.id,
                tr_payee_name: x.tr_payee_name,
                tr_payee_open_bank: x.tr_payee_open_bank,
                tr_payee_bank_card_num: x.tr_payee_bank_card_num,
              })}
            />
          </td>
        );
        break;
      case 'receipt_person_banknum':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              filter={sugPayee()}
              header={payeeSugHeader}
              format="tr_payee_bank_card_num"
              map={x => ({
                id: x.id,
                tr_payee_name: x.tr_payee_name,
                tr_payee_open_bank: x.tr_payee_open_bank,
                tr_payee_bank_card_num: x.tr_payee_bank_card_num,
              })}
            />
          </td>
        );
        break;
      case 'employee_id':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              filter={sugUser()}
              header={['user_name']}
              format="user_name"
              map={x => ({ ...x })}
            />
          </td>
        );
        break;
      case 'receipt_bill_no':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              filter={sugRepayNo()}
              header={repayNoHeader}
              format="bill_no"
              // map={x => ({ bill_id: x.bill_id, bill_no: x.bill_no, expense_id: x.expense_id })}
              map={x => ({ ...x })}
            />
          </td>
        );
        break;
      case 'expense':
        _el = (
          <td key={_key}>
            <Select
              value={row[k]}
              disabled={curDisabled}
              required={curRequired}
              onChange={val => formSet(index, k, val, page)}
              filter={sugExpense()}
              header={expenseSugHeader}
              format="fee_name"
              className="expense"
              placeholder=""
              map={x => ({ ...x })}
            >
              <i className="fn-icon fn-icon-add-rad add-belong" onClick={() => addExpense(page, index)} />
            </Select>
          </td>
        );
        break;
      case 'belong_new':
        _el = (
          <td key={_key}>
            <Input
              value={row.belong_new}
              disabled={curDisabled}
              required={curRequired}
              data-path="belong_new"
              onClick={e => handleBelong(index, page)}
            />
          </td>
        );
        break;
      case 'balance_flag':
        // eslint-disable-next-line no-case-declarations
        const balanceFlagCss =
          // eslint-disable-next-line no-nested-ternary
          row[k] === '高' ? 'balance_flag_max-small' : row[k] === '低' ? 'balance_flag_min-small' : '';
        _el = (
          <td key={_key} className="balance-flag">
            <span className={balanceFlagCss}>{row[k]}</span>
          </td>
        );
        break;
      case 'oil_card':
        _el = (
          <td key={_key}>
            {row?.loan_type === 'gas' ? (
              <Select
                value={row[k]}
                disabled={curDisabled}
                required={curRequired}
                onChange={val => formSet(index, k, val, page)}
                filter={sugOilCard()}
                header={rechargeOilCardHeader}
                format="show_name"
                map={x => ({
                  oil_card_id: x.id,
                  oil_card: x.card_num,
                  balance: x.balance,
                  fuel_card_remain_amount: x.balance,
                  show_name: `${x.card_num}/${x.balance}元`,
                })}
              />
            ) : (
              <div style={{ textAlign: 'center' }}>-</div>
            )}
          </td>
        );
        break;
      default:
        _el = null;
    }
    return _el;
  });
};
