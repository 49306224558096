import React, { PureComponent } from 'react';
import { SlideDrager } from 'components';
import MicroController from 'pages/MicroController';
import { LoadMap } from 'components/utilcomponents/mapAddon/Amap';
import DaoHangTip from '../DaoHangTip/index';

@LoadMap
export default class StdLine extends PureComponent {
  constructor(props) {
    super(props);
    const { opType, lineId, onClose = () => {} } = this.props;
    this.state = {
      stdLineParams: {
        status: opType === 'view' ? 0 : 1,
        lineId,
        onClose,
      },
    };
  }

  handleHide = () => this.stdLineRef.handleHide();

  sliderWrap = ref => (this.stdLineRef = ref);

  render() {
    const { close, popContainer, togglePopWidth } = this.props;
    const config = {
      pageConfig: {
        microKey: 'stdLine',
      },
    };

    const title = (
      <div>
        <span>查看标准线路</span>
        <DaoHangTip />
      </div>
    );

    return (
      <SlideDrager
        className="std-line-slide"
        isShow
        isBiggest
        isHasBiggest={false}
        close={close}
        ref={this.sliderWrap}
        HeaderTitle={title}
        contentDiv={
          <div style={{ height: '100%' }}>
            <MicroController config={config} stdLineParams={this.state.stdLineParams} />
          </div>
        }
        footerDiv={null}
        dragerContainer={popContainer}
        togglePopWidth={togglePopWidth}
      />
    );
  }
}
