exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".route-card__wrap{min-height:178px}.route-card__add{display:flex;justify-content:center;align-items:center;height:178px}.route-card__add .gui-btn{font-size:12px}.route-card__add .gui-btn .fn-icon{margin-right:6px}.route-card__view{display:flex}.route-card__info{flex:1;flex-shrink:0;margin-right:20px;overflow:hidden}.route-card__list{border-top:1px solid #ddd}.route-card__item{display:flex;padding:0 0 0 12px;height:44px;border:1px solid #ddd;border-width:0 1px 1px;font-size:14px;line-height:44px}.route-card__label{margin-right:16px;width:88px;color:#4e5969;text-align:right}.route-card__value{display:flex;flex:1;padding-right:12px;overflow:hidden;white-space:nowrap;text-overflow:ellipsis;font-weight:500;color:#000}.route-card__path{max-width:100%;overflow:hidden}.route-card__map{position:relative;flex:1;flex-shrink:0}.route-card__map__btn{position:absolute;left:50%;-webkit-transform:translateX(-50%);transform:translateX(-50%);bottom:10px}", ""]);

// exports
exports.locals = {
	"prefixCls": "route-card",
	"route-card__wrap": "route-card__wrap",
	"route-card__add": "route-card__add",
	"gui-btn": "gui-btn",
	"fn-icon": "fn-icon",
	"route-card__view": "route-card__view",
	"route-card__info": "route-card__info",
	"route-card__list": "route-card__list",
	"route-card__item": "route-card__item",
	"route-card__label": "route-card__label",
	"route-card__value": "route-card__value",
	"route-card__path": "route-card__path",
	"route-card__map": "route-card__map",
	"route-card__map__btn": "route-card__map__btn"
};