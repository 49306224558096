export const headerDict = {
  receipt_bill_no: '借款单号',
  project_id: '项目客户',
  batch_num: '批次号',
  order_num: '运单号',
  truck_num: '车牌号',
  car_remain_amount: '车辆余额',
  real_pay_amount: '实付金额',
  pre_pay_amount: '预付金额',
  tip_amount: '手续费',
  pre_pay_fuel: '预付油卡',
  pay_total_amount: '应付运输费合计',
  driver_name: '司机姓名',
  driver_remain_amount: '司机余额',
  receipt_person: '收款人',
  receipt_person_bank: '收款人开户行',
  receipt_person_banknum: '收款人银行卡号',
  // refund_amount: '还款金额',
  // refund_status: '还款状态',
  employee_id: '员工姓名',
  employee_remain_amount: '员工余额',
  predict_refund_time: '预计还款日期',
  current_settled_amount: '未结金额',
  amount: '未结金额',
  abstract: '摘要',
  loan_type: '借支类型',
  oil_card: '充值油卡',
  car_total_balance: '车辆总余额',
  driver_total_balance: '司机总余额',
  employee_total_balance: '员工总余额',
};
