import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Load, SlideDrager, UploadFile, UploadAttach } from 'components';
import { bem, fetchJAVA, showInfo } from 'utils';
import { ERROR } from 'constants';
// import { getPayModeArr } from 'components/commoncomponents/payMode/tool'
import FeeBreakdown from 'components/commoncomponents/feeBreakdown';
import PayMode from 'components/commoncomponents/payMode';
import _ from 'lodash';

import adaptor from './adaptor';
import renderAttr from './attr';
// import renderList from './list'
import renderExtra from './extra';
import renderAudit from './audit';
import renderFooter from './footer';
import { opSave, opAudit } from './action';

import { prefixCls } from './index.scss';
import { useloanTypeBillTypes, isCarAndDriverRepay } from './constant';

const cls = bem(prefixCls);

export default class CapitalFlow extends PureComponent {
  static propTypes = {
    usedFor: PropTypes.string, // 用途 add：创建，update：修改，view：查看，audit：审核
    /**
     * 单据类型
     * 车辆借款单：loan_car
     * 车辆还款单：repay_car
     * 司机借款单：loan_driver
     * 司机还款单：repay_driver
     * 员工借款单：loan_employee
     * 员工还款单：repay_employee
     * 收支付款单：income_pay
     */
    billType: PropTypes.string,
    auditOperation: PropTypes.string,
    titleName: PropTypes.string, // 单据名称
    preInfo: PropTypes.object, // 上一页面带过来的信息
    isInitReq: PropTypes.bool, // 初始是否请求接口
    reqParam: PropTypes.object, // 请求接口参数
    reqUrl: PropTypes.string, // 请求接口地址
    cancelCallBack: PropTypes.func, // 取消回调
    completeCallBack: PropTypes.func, // 完成后回调
    rebut_direct: PropTypes.bool, // 是否可以 驳回直送
  };

  static defaultProps = {
    usedFor: 'add',
    billType: '',
    titleName: '单据',
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false, // 是否加载状态
    };
    // 用于记住要计算的输入框是否手动改过
    this.inputMemory = {};
  }

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    const { usedFor, billType, preInfo = {} } = this.props;

    // 如果使用借支类型。则借支类型必填
    if (useloanTypeBillTypes.includes(billType)) {
      const loan_type = {
        key: 'loan_type',
        label: '借支类型',
        required: true,
        show: true,
        disables: false,
      };
      const oil_card = {
        key: 'oil_card',
        label: '充值油卡',
        required: false,
        show: true,
      };
      preInfo.settings?.table_fields_setting?.value.push(loan_type, oil_card);
    }
    const _state = adaptor({
      enums: preInfo.enums,
      loadInfo: preInfo.loadInfo,
      settings: preInfo.settings,
      payModeEditable: preInfo.pay_mode_editable,
      billType,
      usedFor,
    });

    // 添加借支类型，默认现金
    if (useloanTypeBillTypes.includes(billType)) {
      if (usedFor === 'add') {
        _state.listData.push({ loan_type: 'cash' });
      }

      // 车辆、司机还款单新增时，借支类型为现金且不可能修改
      if (isCarAndDriverRepay.includes(billType)) {
        _state.fieldSet.loan_type.disables = true;
        _state.listHeader.loan_type.disables = true;
      }
    }
    this.setState({ ..._state });
  };

  handleRefresh = async () => {
    const { reqUrl, reqParam = {} } = this.props;
    const req = { ...reqParam };
    const fetchRes = await fetchJAVA(reqUrl, { method: 'POST', body: { req } });
    if (fetchRes.errno !== 0) {
      return showInfo(ERROR, fetchRes.errmsg);
    }
  };

  // 关闭侧拉
  closeCapitalFlow = () => {
    this.props.cancelCallBack && this.props.cancelCallBack();
    this.props.close();
  };

  // 切换 loading 状态
  switchLoadState = () => {
    // const fee = this.capitalFlowPaymode.getData()
    // this.setState(state => ({ isLoading: !state.isLoading, feeInfo: fee.data }))
    this.setState(state => ({ isLoading: !state.isLoading }));
  };

  handleBtnClick = type => {
    const _self = this;

    switch (type) {
      case 'save': // 保存
        opSave(_self, 'save');
        break;
      case 'saveAndCreate': // 保存并新增
        opSave(_self, 'saveAndCreate');
        break;
      case 'update': // 修改
        opSave(_self, 'update');
        break;
      case 'pass': // 审核通过
        opAudit(_self, 'pass');
        break;
      case 'reject': // 审核拒绝
        opAudit(_self, 'reject');
        break;
      case 'rebut': // 审核驳回
        opAudit(_self, 'rebut');
        break;
      default:
        // 取消
        _self.closeCapitalFlow();
    }
  };

  getPayModeList = () => this.capitalFlowPaymode.payModeTable.getStateCache();
  renderPayMode = () => {
    const { feeInfo = [], enums = {} } = this.state;
    if (!feeInfo.length) return null;
    const { usedFor } = this.props;
    const _enum = { pay_mode: enums.pay_mode };
    const isView = !!(usedFor === 'view' || usedFor === 'audit');

    return (
      <div className={cls('paymode')}>
        <PayMode
          isView={isView}
          isGearing
          isAddOilCard
          data={[...feeInfo]}
          enumerations={_enum}
          payModeEnum={enums.pay_mode_info}
          ref={r => (this.capitalFlowPaymode = r)}
        />
      </div>
    );
  };

  handleUploadImg = imgList => {
    // const fee = this.capitalFlowPaymode.getData()
    // this.setState({ imgList: [...imgList], feeInfo: fee.data })
    this.setState({ imgList: [...imgList] });
  };
  renderUploadImg = () => {
    const { usedFor } = this.props;
    const { imgField = {}, imgList = [] } = this.state;
    if (!imgField.show || (usedFor === 'view' && imgList.length === 0)) return null;

    return (
      <div className={cls('upload-img')}>
        <h3>
          图片上传<span>*最多上传10张</span>
        </h3>
        <UploadFile
          fileList={imgList}
          disabled={imgField.disables}
          onChange={val => this.handleUploadImg(val)}
          maxNum="10"
          accept="image"
          type="balance"
        />
      </div>
    );
  };

  sendFiles = param => {
    // const { filesList = [] } = this.state
    const fee = this.capitalFlowPaymode.getData();
    this.setState({ filesList: [...param.data], feeInfo: fee.data });
  };
  renderFiles = () => {
    const { usedFor } = this.props;
    const { fileField = {}, filesList = [] } = this.state;

    if (!fileField.show || (usedFor === 'view' && filesList.length === 0)) return null;

    return (
      <div className={cls('files-wrap')}>
        <UploadAttach
          type="bill"
          iconType="icon-clip"
          data={filesList}
          disable={fileField.disables}
          onChange={param => this.sendFiles(param)}
        />
      </div>
    );
  };

  renderList = () => {
    const { usedFor, billType, expenseType, valHub, listHeader = {}, listData = [] } = this.state;
    const _preParam = _.cloneDeep(valHub);
    if (_preParam) _preParam.billType = billType;
    const _isAddSub = usedFor !== 'view';

    return (
      <FeeBreakdown
        data={listData}
        header={listHeader}
        preParam={_preParam}
        isAddSub={_isAddSub}
        billType={billType}
        usedFor={usedFor}
        type={expenseType}
        ref={r => (this.feeBreakdown = r)}
      />
    );
  };

  renderAttr = () => renderAttr(this);
  renderExtra = () => renderExtra(this);
  renderAudit = () => renderAudit(this);
  renderForType = () => {
    const { usedFor, titleName } = this.props;
    return (
      <div className={cls()} ref={r => (this.refCapitalFlow = r)}>
        <h1 className={cls('header')}>{titleName}</h1>
        {this.renderAttr()}
        {this.renderList()}
        {this.renderPayMode()}
        {this.renderExtra()}
        {this.renderUploadImg()}
        {this.renderFiles()}
        {usedFor === 'audit' && this.renderAudit()}
      </div>
    );
  };

  renderTitle = () => {
    const { usedFor, titleName = '单据' } = this.props;
    let str;
    switch (usedFor) {
      case 'update':
        str = '修改';
        break;
      case 'view':
        str = '查看';
        break;
      case 'audit':
        str = '审核';
        break;
      default:
        str = '创建';
    }
    return str + titleName;
  };

  renderFooter = () => renderFooter(this);

  render() {
    const { usedFor } = this.props;
    if (usedFor === undefined) return null;

    const { isLoading } = this.state;
    return (
      <Load spinning={isLoading}>
        <SlideDrager
          isShow
          slideWidth={1030}
          isHasDrager={false}
          HeaderTitle={this.renderTitle()}
          contentDiv={this.renderForType()}
          footerDiv={this.renderFooter()}
          close={this.closeCapitalFlow}
          ref={r => (this.bookkeep = r)}
        />
      </Load>
    );
  }
}
