import { postJAVA, showInfo, handleAPIResponse, alert, hasPermission } from 'utils';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { CHECK } from 'constants';
import { Tabs, Radio, Alert } from '@gui/web-react';
import Load from '../../utilcomponents/loading/Load';
import { ModalDialog, Button, Icon } from 'components';
import copy from 'copy-to-clipboard';
import openAgreement from './agreement';
import cytUpgradeGuider from 'utils/cytUpgradeGuider';

import { prefixCls } from './index.scss';

export default class Recharge extends PureComponent {
  static defaultProps = {
    title: '充值',
  };

  static propTypes = {
    id: PropTypes.string,
    close: PropTypes.func,
    refreshTab: PropTypes.func,
    title: PropTypes.string,
    handleAudit: PropTypes.func,
    handleSuccess: PropTypes.func,
    handleCancel: PropTypes.func,
    cytUpgradeGuideOptions: PropTypes.object,
  };
  constructor(props) {
    super(props);
    this.state = {
      QRCodeDataURL: '',
      // first: false,
      // first_welfare: {
      //   pay_amount: '', // 支付金额（元）
      //   exchange_integral: '', // 兑换积分
      //   welfare_integral: '', // 优惠积分
      // },
      welfare_gear: [],
      loading: false,
      chooseValue: '',
    };

    this.recharge_nos = [];
    this.isSuccess = false; // 充值是否成功
  }
  fetchDetails() {
    this.setState({
      loading: true,
    });
    handleAPIResponse(postJAVA('/cmm-finance/recharge/getWelfare', {}))
      .then(res => {
        if (res) {
          this.setState(
            {
              ...res,
            },
            () => {
              const { welfare_gear } = res;
              if (Array.isArray(welfare_gear) && welfare_gear.length > 0) {
                this.setState({
                  chooseValue: welfare_gear[0].pay_amount,
                });
                this.fetchQrCode(welfare_gear[0].pay_amount);
              }
            },
          );
        }
      })
      .catch(() => {
        this.setState({
          loading: false,
        });
      });
  }
  fetchQrCode(price) {
    this.setState({
      loading: true,
    });

    handleAPIResponse(
      postJAVA('/cmm-finance/recharge/getQrcode', {
        pay_amount: price, // 支付金额(元)
        pay_type: 1, // 支付方式 1、微信 2、支付宝 3、银联
      }),
    )
      .then(({ qrcode, recharge_no }) => {
        this.setState({
          loading: false,
        });
        if (qrcode) {
          this.setState({
            QRCodeDataURL: qrcode,
          });

          this.recharge_nos.push(recharge_no);
          this.fetchRechargeResult();
        }
      })
      .catch(error => {
        this.setState({
          loading: false,
          QRCodeDataURL: '',
        });
      });
  }
  renderFirstCharge(data) {
    if (data.is_first) {
      return (
        <div className="top_container">
          <div className="charge_btn">首充特惠</div>
          <div>
            首次充值满{data.first_welfare.pay_amount}元额外赠送
            <span className="font_red">{data.first_welfare.welfare_integral}积分</span>
          </div>
        </div>
      );
    }
  }

  // 是否显示购买货物安全管理保障服务按钮（有权限开通)
  hasShowFreightGuarantee() {
    const showFreightGuarantee = hasPermission('buyFreightGuaranteeV1');
    if (showFreightGuarantee) {
      return '、货物安全管理保障服务';
    } else {
      return '';
    }
  }

  renderChargeExplain() {
    return (
      <div className="charge-explain_wrap">
        <div className="title">服务计费说明:</div>
        <ul>
          <li>1元=10积分</li>
          <li>`您充值的积分可用于运单自动跟单、查询轨迹、电子合同服务${this.hasShowFreightGuarantee()}`</li>
          <li>车辆实时位置订阅10积分每24小时，轨迹查询10积分每天，电子合同单份签署扣除7个积分</li>
          <li>客服电话：010-53154195</li>
        </ul>
        <div className="explain_bottom">
          具体服务内容请参考
          <span className="agree_link" onClick={openAgreement}>
            《运单车辆跟踪定位服务协议》
          </span>
          ，充值即代表同意协议
        </div>
      </div>
    );
  }
  renderTableRow(data) {
    return data.map(item => {
      return item.welfare_integral ? ( // ZNGC2-841【财运通】账户充值积分赠送调整
        <div className="table_row">
          <div className="row_left">{item.welfare_name}</div>
          <div className="row_right">赠送{item.welfare_integral}积分</div>
        </div>
      ) : (
        <></>
      );
    });
  }
  renderTable() {
    return (
      <div className="table_wrap">
        <div className="table_name">
          <div>收款账户信息</div>
          <div className="table_copy" onClick={this.btnCopy}>
            <img src={require('images/accout/copy.svg?url')} alt="" />
            <span style={{ marginLeft: '3px' }}>复制</span>
          </div>
        </div>
        <div className="table_content">
          <div className="table_row">
            <div className="row_left">开户名</div>
            <div className="row_right">蚌埠吉行物联网科技有限公司</div>
          </div>
          <div className="table_row">
            <div className="row_left">开户行</div>
            <div className="row_right">中国光大银行大连分行营业部</div>
          </div>
          <div className="table_row">
            <div className="row_left">收款账号</div>
            <div className="row_right">35710188001142020</div>
          </div>
          <div className="table_row">
            <div className="row_left" style={{ textAlign: 'center' }}>
              转账备注
              <span style={{ color: '#f53f3f' }}>(请务必填写正确)</span>
            </div>
            <div className="row_right" style={{ color: ' #f53f3f' }}>
              CYT{window.group_id}
            </div>
          </div>
        </div>
      </div>
    );
  }
  btnCopy() {
    copy(`
    开户名: 蚌埠吉行物联网科技有限公司
    开户行: 中国光大银行大连分行营业部
    收款账号: 35710188001142020
    转账备注(请务必填写正确): CYT${window.group_id}
    `);
    showInfo(CHECK, '复制成功');
  }
  renderFee() {
    return (
      <div className="table_wrap">
        <div className="table_name">
          <div>充值方案</div>
        </div>
        <div className="table_content">{this.renderTableRow(this.state.welfare_gear)}</div>
      </div>
    );
  }
  handlePrice(value) {
    this.setState({
      chooseValue: value,
    });
    this.fetchQrCode(value);

    console.log(value);
  }
  renderRadio(priceList = []) {
    if (priceList.length) {
      return (
        <Radio.Group direction="vertical" value={this.state.chooseValue} onChange={value => this.handlePrice(value)}>
          {priceList.map(item => {
            return (
              <Radio key={item.pay_amount} value={item.pay_amount}>
                {({ checked }) => {
                  return (
                    <div
                      className={`custom-checkbox-card ${checked ? 'custom-checkbox-card-checked' : ''} flex-container`}
                    >
                      <div className="check-top-icon">
                        <img src={require('images/accout/topCheck.svg?url')} alt="" />
                      </div>
                      <div className="custom-checkbox-card-mask">
                        {checked ? (
                          <img src={require('images/accout/checked.svg?url')} alt="" />
                        ) : (
                          <img src={require('images/accout/notCheck.svg?url')} alt="" />
                        )}
                        <div className="custom-checkbox-card-mask-dot" />
                      </div>
                      <div className="flex-container" style={{ justifyContent: 'space-between', width: '100%' }}>
                        {item.welfare_integral ? ( // ZNGC2-841【财运通】账户充值积分赠送调整
                          <div className="custom-checkbox-card-content">
                            {item.exchange_integral}积分
                            {/* <span className="font_red">赠送{item.welfare_integral}积分</span> */}
                          </div>
                        ) : (
                          <div className="custom-checkbox-card-content">{item.exchange_integral}积分</div>
                        )}
                        <div className="custom-checkbox-card-price">{item.pay_amount}元</div>
                      </div>
                    </div>
                  );
                }}
              </Radio>
            );
          })}
        </Radio.Group>
      );
    }
    return <div style={{ width: '360px', textAlign: 'center' }}>暂无数据</div>;
  }
  renderQrCode() {
    return (
      <div className="qrCode_wrap">
        <div className="qrCode_container">
          <img src={this.state.QRCodeDataURL} />
        </div>
        <div className="qrCode-desc">请扫码支付(微信或支付宝等)</div>
      </div>
    );
  }
  async fetchRechargeResult() {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => this.fetchRechargeResult.call(this), 1000);

    if (this.recharge_nos.length) {
      const res = await handleAPIResponse(
        postJAVA('/cmm-finance/recharge/rechargeResult', {
          recharge_nos: this.recharge_nos.join(','),
        }),
      );

      // pay_status 0:待支付 1:支付中 2:已支付 3:支付失败
      const isSuccess = res.some(({ pay_status }) => pay_status === 2);

      if (isSuccess) {
        // 有支付成功的，关闭充值弹窗
        this.rechargeDialog.handleHide();
        this.isSuccess = true;
        clearTimeout(this.timer);

        alert(CHECK, '充值成功', { confirm: '关闭' }).then(
          () => this.props.handleSuccess && this.props.handleSuccess(),
        );
      }
    }
  }
  async componentDidMount() {
    await this.fetchDetails();

    this.fetchRechargeResult();
    console.log('rechargeDialog', this.recharge_nos);
    // this.query.run();
    // this.scanFocus();
    // this.overTimeInit();
    // if (window.whosyourdaddy) {
    //   const _self = this;
    //   window.scan = _self;
    // }
    if (this.props.cytUpgradeGuideOptions) {
      // 默认参数
      const initCytUpgradeGuideOptions = {
        type: 'inner',
        name: '申请购买设备or软连接',
        place: '付费充值弹窗',
        guideImage: 'recharge-banner.png',
        show: true,
        height: '168px',
        applyPosition: {
          left: '76px',
          top: '107px',
        },
      };
      this._guider = cytUpgradeGuider({ ...initCytUpgradeGuideOptions, ...this.props.cytUpgradeGuideOptions });
    }
  }
  componentWillUnmount() {
    clearTimeout(this.timer);
    !this.isSuccess && this.props.handleCancel && this.props.handleCancel();
    this._guider?.destroy();
  }

  render() {
    const { TabPane } = Tabs;
    const { title } = this.props;
    const { loading } = this.state;
    const content = (
      <div className={`${prefixCls}-content`}>
        <Load spinning={loading}>
          {this._guider ? <div ref={ref => ref?.appendChild(this._guider.getNode())} /> : null}
          <Tabs defaultActiveTab="1">
            <TabPane key="1" title="扫码支付">
              <div className="flex-container weChat_wrap">
                {this.renderRadio(this.state.welfare_gear)}
                {this.renderQrCode()}
              </div>
              {this.renderChargeExplain()}
            </TabPane>

            <TabPane
              key="2"
              title={
                <div>
                  <span style={{ marginRight: '5px' }}>银行转账</span>
                  <Icon iconType="icon-help" tipsCls="e-sign-help" tips="银行转账预计5分钟后积分到账" />
                </div>
              }
            >
              <Alert type="warning" content="温馨提示：本充值无法用于网货结算，切勿错充！" />
              {this.renderTable()}
              {/* {this.renderFee()} */}
              {this.renderChargeExplain()}
            </TabPane>
          </Tabs>
        </Load>
      </div>
    );
    const bottom = [
      <Button key={2} onClick={() => this.rechargeDialog.handleHide()} type="primary">
        关闭
      </Button>,
    ];
    return (
      <ModalDialog
        title={title}
        content={content}
        bottom={bottom}
        isShow
        classname={prefixCls}
        closable
        close={this.props.close}
        maskClosable={false}
        style={{ width: 720 }}
        ref={r => (this.rechargeDialog = r)}
      />
    );
  }
}
