import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  postData,
  getComVal,
  deleteProps,
  filterListColumnByKeyList,
  showInfo,
  postJAVA,
  confirmWithReasonSync,
  g7Done,
  filterListColumnByValueList,
  typeIs,
  tableDataCheck,
  createTip,
  formateTableCheckTips,
  checkTotalTrans,
  confirmWithReason,
  getTotalPay,
  showCheckFeeTips,
  checkTransTotalSum,
  switchWeight,
  splitWayErr,
  t_cluster,
  emitListBtnOp,
  proxyListPage,
  confirm,
  bigComputed,
} from 'utils';
import { ERROR, INFO, WARN, EMPTY_CHECK_TIPS } from 'constants';
import { opTips } from 'utils/checkInfo';
import { PopTip, PopUp, ShowInfo, BalanceEmpty, BatchOp } from 'components';
import actions from 'actions';
import { checkSend, checkMsgHistory } from 'utils/checkMsgSend';
import BatchConfirmForm from 'components/commoncomponents/BatchDetail/batchConfirmForm';
import commonErrorTip from 'utils/business/order/commonErrorTip';
import limitDialog from 'utils/business/truck/limitDialog';
import { post } from '@/utils/fetchApi';
import config from '../config';
import './index.scss';
import { danger_field_key_map } from 'constants/setting/rosSetting';

/*
  挑单页面保存时数据处理
 */
const BiFormateData = (pageKey, ComposedComponent) =>
  class extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {};
    }
    static defaultProps = {};
    static childContextTypes = {
      truckLoad: PropTypes.func,
      shuttleLoad: PropTypes.func,
      deliveryLoad: PropTypes.func,
      taskDeliveryLoad: PropTypes.func,
      orderSign: PropTypes.func,
      orderTrans: PropTypes.func,
      transCheckPayment: PropTypes.func,
      orderDispatch: PropTypes.func,
      deliveryBatchDispatch: PropTypes.func,
      reservationAssign: PropTypes.func,
      outWMSLoad: PropTypes.func,
      moveWMSLoad: PropTypes.func,
      bindDevice: PropTypes.func,
      completePack: PropTypes.func,
      moveStockLoad: PropTypes.func,
      batchDispatchDriver: PropTypes.func,
      manualSchedule: PropTypes.func,
      composeOrderLine: PropTypes.func,
      composeOrderGoods: PropTypes.func,
      composeOrderGoodsByFrontend: PropTypes.func,
    };
    getChildContext = () => ({
      truckLoad: this.truckLoad,
      shuttleLoad: this.shuttleLoad,
      deliveryLoad: this.deliveryLoad,
      taskDeliveryLoad: this.taskDeliveryLoad,
      orderSign: this.orderSign,
      orderTrans: this.orderTrans,
      transCheckPayment: this.transCheckPayment,
      orderDispatch: this.orderDispatch,
      deliveryBatchDispatch: this.deliveryBatchDispatch,
      reservationAssign: this.reservationAssign,
      outWMSLoad: this.outWMSLoad,
      moveWMSLoad: this.moveWMSLoad,
      bindDevice: this.bindDevice,
      completePack: this.completePack,
      moveStockLoad: this.moveStockLoad,
      batchDispatchDriver: this.batchDispatchDriver,
      manualSchedule: this.manualSchedule,
      composeOrderLine: this.composeOrderLine,
      composeOrderGoods: this.composeOrderGoods,
      composeOrderGoodsByFrontend: this.composeOrderGoodsByFrontend,
    });
    isUpdate = (item, mapKey) => {
      let isUpdate = 0;
      Object.keys(mapKey).forEach(key => {
        isUpdate =
          isUpdate ||
          (item[mapKey[key]] !== undefined && +item[mapKey[key]] !== 0 && +item[mapKey[key]] !== +item[key]);
      });
      return isUpdate ? 1 : 0;
    };
    /*
    装车、短驳、送货、签收、中转的数据处理
  */
    formateData = (value, submitData, tableValid, pageType, isSplit = true, otherProps) => {
      console.log(value);
      if (value === undefined) return {};
      const cardFormInfo = this.getCardFormData(value.cardInfo || {});
      let feeInfo;
      const _feeInfo = [];
      if (value.feeInfo !== undefined) {
        value.feeInfo.map(row => {
          const _row = row;
          // submitData.boolToNum.forEach((item) => {
          //   _row[item] = row[item] ? 1 : 0
          // })
          Object.keys(row).forEach(key => {
            _row[key] = typeIs(row[key], 'string') ? (row[key] || '').trim() : row[key];
            if (submitData.boolToNum && submitData.boolToNum.includes(key)) {
              _row[key] = row[key] ? 1 : 0;
            }
          });
          _feeInfo.push(_row);
          return _feeInfo;
        });
      }
      if (value.feeInfo !== undefined) {
        feeInfo = filterListColumnByKeyList(_feeInfo || [], submitData.feeColumnList || []);
      }
      const tmpInfos = value.tableInfo || {};
      const header = tmpInfos.header || {};
      const data = tmpInfos.list || [];
      // 添加表格验证
      const checkRes = tableDataCheck(data, header, tableValid);
      if (!checkRes.isPass) {
        createTip(formateTableCheckTips(checkRes), ERROR).show();
        return false;
      }
      const tableForm = tmpInfos.form || {};
      let tableInfo = [];
      // 开启拆单的情况下， reshuffle处理
      if (isSplit) {
        data.forEach(iitem => {
          const item = iitem;
          let otherParam = {};
          const splitInfo = item.od_link_split_info;
          if (splitInfo) {
            let gWeight = splitInfo.g_weight;
            let totalWeight = splitInfo.weight;
            // 拆单显示的均为换算后的单位，往后台传递，需要换算为KG
            if (
              window.company_setting.weight_unit &&
              window.company_setting.weight_unit.value === 'T' &&
              (!otherProps || otherProps.orderType !== '订单')
            ) {
              gWeight = gWeight.map(w => parseFloat(w * 1000).toFixed(3));
              totalWeight = parseFloat(totalWeight * 1000).toFixed(3);
            }
            delete splitInfo.g_info;
            otherParam = {
              ...splitInfo,
              g_info: {
                g_weight: gWeight,
                g_volume: splitInfo.g_volume,
                g_num: splitInfo.g_num,
                g_suit: splitInfo.g_suit,
                g_id: splitInfo.g_id,
                g_wms_stock_id: splitInfo.g_wms_stock_id,
              },
              is_update: 1,
              weight: totalWeight,
            };
          } else if (item.split_default_info) {
            otherParam = { ...item.split_default_info };
            item.is_zu = otherParam.is_zu;
          } else if (item.num || item.weight || item.volume) {
            const saveMapKey = config[pageType] && (config[pageType].saveMapKey || {});
            // 是否是补装车
            const isReload =
              otherProps && otherProps.is_reload && otherProps.is_reload.find(id => +id === +item.od_link_id);
            if (isReload) {
              // 是否在本批次装过，未装过时 件数体积等取库存数量 否则 加和处理
              if (+otherProps.b_link_id !== +item.b_tr_link_id) {
                item.b_tr_load_n = item.wh_n;
                item.b_tr_load_v = item.wh_v;
                item.b_tr_load_w = item.wh_w;
                item.b_tr_load_s = item.wh_s;
                item.b_tr_load_actual_price = item.wh_actual_price;
              } else {
                item.b_tr_load_n = (+item.wh_n || 0) + (+item.b_tr_load_n || 0);
                item.b_tr_load_v = (+item.wh_v || 0) + (+item.b_tr_load_v || 0);
                item.b_tr_load_w = (+item.wh_w || 0) + (+item.b_tr_load_w || 0);
                item.b_tr_load_s = (+item.wh_s || 0) + (+item.b_tr_load_s || 0);
                item.b_tr_load_actual_price = (+item.wh_actual_price || 0) + (+item.b_tr_load_actual_price || 0);
              }
            }
            const isUpdate = this.isUpdate(item, saveMapKey);
            otherParam.is_update = isUpdate;
            if (isUpdate) {
              // isUpdata ： 表示拆单
              const totalKey = config.spTypeMap[+item.sub_sp_type];

              Object.keys(saveMapKey).forEach(subItem => {
                otherParam[subItem] = item[saveMapKey[subItem]];
              });
              // 为了兼容提货percent比例
              // let percent = otherParam[totalKey] / item[totalKey]
              let percent = 1;
              if (totalKey) {
                const total = totalKey === 'actual_price' ? 'actual_price_total' : totalKey;
                percent = otherParam[totalKey] / item[total];
              } else {
                otherParam.is_update = 0; // 是拆弹的情况下 一定有totalKey 能计算到比例
              }
              window.company_setting.split_order_fee &&
                window.company_setting.split_order_fee.splitValue &&
                window.company_setting.split_order_fee.splitValue === 'spec' &&
                (percent = item.split_percent || 0);
              otherParam = { ...otherParam, percent };
              // 如果有回传stock的情况， 将stock放到g_info中, 多货的情况下 批次拆单发车后再送货 货物信息有问题
              item.stock_goods && (otherParam.g_info = item.stock_goods);
            }
          }
          // 拆单2.0 如果该单是主子单且未操作拆单，则还是主子单
          let isZZD = iitem.zzd_val;
          if (isZZD === undefined || isZZD === null) {
            isZZD = +iitem.is_zu === 1 && !_.isEmpty(item.split_op) && +item.next_od_link_id === 0 ? 1 : 0;
          }
          if (otherParam.is_zzd === undefined) {
            otherParam.is_zzd = isZZD;
          }
          otherParam.is_zzd = +otherParam.is_zzd === 1 ? 1 : 2;
          const unCalcPercent =
            (window.company_setting.split_order_fee &&
              window.company_setting.split_order_fee.splitValue &&
              window.company_setting.split_order_fee.splitValue === 'spec') ||
            false;
          otherParam.is_update === 0 && (otherParam.percent = 1);
          if (unCalcPercent && +otherParam.is_zzd === 1) {
            otherParam.percent = 1;
          }
          tableInfo.push(Object.assign({}, item, { is_update: 0 }, otherParam));
        });
      } else {
        tableInfo = data;
      }
      // 防止产生od_link_id不存在的情况
      let filterKey = pageType === 'assignReservation' ? 'reservation_num' : 'od_link_id';
      if (pageType === 'outWMS' || pageType === 'moveWMS') {
        filterKey = 'id';
      }
      tableInfo = tableInfo.filter(x => x[filterKey]);
      tableInfo = filterListColumnByKeyList(tableInfo || [], submitData.tableColumnList);
      return { feeInfo, tableInfo, tableForm, cardFormInfo };
    };
    /*
    格式化table中的数据
  */
    getCardFormData = obj => {
      console.log('getCardFormData');
      const data = {};
      Object.keys(obj).forEach(key => {
        const value = getComVal(obj[key]);
        let formateValue = value;
        if (obj[key].type === 'Ccombiner') {
          formateValue = getComVal(value[key]);
        }
        if (obj[key].type === 'SelectDrop' && value !== undefined && typeof value === 'object') {
          // 默认传递showKey, 传递uniquekey的需要自己指定
          let { showKey } = obj[key].otherProps;
          if (config.cardFormList.uniqueKeyList.indexOf(key) !== -1) {
            showKey = obj[key].otherProps.uniqueKey;
          }
          if (obj[key].otherProps.isMultiple) {
            formateValue = value.map(i => i[showKey]);
          } else {
            formateValue = value[0] ? value[0][showKey] : formateValue;
          }
          if (config.cardFormList.allList.indexOf(key) !== -1) {
            formateValue = value[0];
          }
          const wmsBoth = config.cardFormList.wmsBothKey;
          if (Object.keys(wmsBoth).includes(key)) {
            data[wmsBoth[key]] = value[0].id;
          }
        }
        if (obj[key].type !== 'Icon') {
          data[key] = formateValue;
        }
      });
      return data;
    };
    /*
    操作后的check信息
  */
    completeStatus = (pageType, completeStatus, callback, ext = {}) => {
      this.opTipsClose && this.opTipsClose();
      const { errno } = completeStatus;
      // 对特殊错误特殊处理
      const errnoAttr = {
        2500: {
          cancelBtnName: '网点中转',
          closeBtnName: '网点中转',
          failedCallback: () => {
            const dataList = completeStatus.req.load_od_link_detail;
            const shouldTransOrderNums = completeStatus.res.failed_detail.map(item => item.number);
            const selected = [];
            dataList.forEach((item, index) => {
              if (shouldTransOrderNums.includes(item.order_num)) {
                selected.push(index);
              }
            });
            const listPage = proxyListPage(dataList, { selected });
            emitListBtnOp({ listPage, key: 'trans_point' });
          },
        },
      };
      if (errno === 2500) {
        completeStatus.req.errno = 0; // eslint-disable-line
      }
      const tipAttr = {
        opType: ext.pageType || pageType,
        resData: completeStatus,
        sucCallback: callback,
        ext,
        orderType: ext.orderType,
        continueCallback: (sucId, closeDialog) => {
          const { req } = completeStatus;
          const key = config[pageType].listKey || 'load_od_link_detail';
          const checkKey = config[pageType].checkKey || 'od_link_id';
          if (pageType === 'assignReservation') {
            req.force = true;
          }
          const formateId = [];
          sucId.forEach(x => formateId.push(+x || (x && x[checkKey])));
          const formate = ext.formate !== undefined ? ext.formate : value => parseInt(value, 10);
          req[key] = filterListColumnByValueList(req[key], checkKey, formateId, formate);
          this.opTipsClose = closeDialog;
          this.sendReq(
            pageType,
            req,
            false,
            resData => {
              closeDialog(resData);
              callback(resData);
            },
            { pageType: ext.pageType, url: ext.url, btnKey: ext.btnKey },
          );
        },
        ...errnoAttr[errno],
      };
      opTips(tipAttr);
    };
    /*
    操作后的发请求
  */
    sendReq = (pageType, pack, check, callback, ext) => {
      console.log('sendReq', pageType, pack, check, callback, ext);
      const req = pack;
      const isPreLoad = _.get(window, 'company_setting.task_auto_loading.checked') === 'yes'; // 已装车
      let url;
      req.isCheck = check;
      req.truck_id = ext.truck_id;
      req.dr_id = ext.dr_id;
      ext && (url = ext.url);
      if (ext && ext.pickOrder && ext.pickOrder.setRightButtonLoadingStatus) {
        ext.pickOrder.setRightButtonLoadingStatus(ext.btnKey);
      }
      // // 读取系统设置
      // if (isPreLoad) {
      //   pageType = 'truck'
      // } else {
      //   pageType = 'pre_load'
      // }
      // // 已装车
      // if (!(+ext.batchSt === 5 || +ext.batchSt === 13 || +ext.batchSt === 16)) {
      //   pageType = 'truck'
      // } else {
      //   pageType = 'pre_load'
      // }
      postData(url || config[pageType].completeUrl, req, res => {
        console.log('config[pageType]', config[pageType]);
        if (ext && ext.pickOrder && ext.pickOrder.setRightButtonLoadingStatus) {
          ext.pickOrder.setRightButtonLoadingStatus(ext.btnKey, false);
        }
        let resData = res.data;
        let specialTips = '';
        const resRes = resData.res;
        const failDetail = (resRes && resRes.failed_detail) || [];
        if (ext && ext.firstCheckCallback && typeof ext.firstCheckCallback === 'function') {
          ext.firstCheckCallback(resData, checkRes => {
            // 更改掉发送请求前的运单信息
            resData = checkRes.resData;
            specialTips = checkRes.specialTips;
            this.completeStatus(pageType, resData, callback, { specialTips, ...ext });
          });
        } else if (+resData.errno === -152) {
          // -152是车辆配载使用平台车队报错
          // const deepRes = res.data.res
          const retryReq = { b_basic_id: resRes.b_basic_id };
          const cancelReq = {
            req: {
              b_link_id: resRes.b_link_id,
              company_id: resRes.company_id,
              type: 'tr_up',
            },
            param: {
              basicId: resRes.b_basic_id,
              batchId: resRes.b_link_id,
              batchNo: resRes.car_batch,
              comId: resRes.company_id,
              hasTab: true,
              isEdit: '',
              type: 'view',
            },
          };
          const other = { specialTips, ...ext };
          const param = { pageType, resData, callback, other };
          this.retry(param, retryReq, cancelReq);
        } else if (
          pageType === 'sign' &&
          +resData.errno === 0 &&
          failDetail &&
          failDetail.length &&
          failDetail[0].msg_ext
        ) {
          // xxx是需要提示余额不足弹框的errno
          const { btnKey } = ext;
          new PopUp(BalanceEmpty, {
            title: '账户余额不足',
            notice: failDetail[0].msg_ext,
            iconType: WARN,
            btnKey,
            callback: () => callback(resData),
          }).show();
        } else if (resData.errno === 900) {
          showInfo(
            ERROR,
            resData.errmsg,
            true,
            {
              confirm: () => {
                callback(resData);
              },
            },
            false,
            '提示',
            undefined,
            { confirm: '刷新' },
          );
        } else if (+resData.errno === -399) {
          limitDialog(resData.errmsg);
        } else if (resData.errno === -330) {
          showInfo(ERROR, resData.errmsg);
        } else {
          const isPack = { is_pack: resRes && resRes.is_pack } || {};
          const extObj = Object.assign({}, ext);
          if (resData.errno === -122) {
            const arr = [];
            const errObj = resData.res.ledger_info || {};
            Object.keys(errObj).forEach(x => {
              arr.push(errObj[x].title);
            });
            if (arr.length) {
              extObj.specialCause = [
                { msg: `[${arr.join(',')}]已挂账，启用了发车批次挂账同时对运单挂账，如需增减运单，请先操作取消挂账。` },
              ];
              extObj.customFailDesc = resData.errmsg;
            }
          }
          console.log('ext param =', extObj);
          this.completeStatus(pageType, resData, callback, { specialTips, ...extObj, ...isPack });
        }
      });
    };
    /**
     * "重试"弹框
     */
    retry = (param, req, cancelReq) => {
      const btns = this.retryBtn(param, req, cancelReq);
      const cnt = this.retryCnt();
      new PopUp(ShowInfo, {
        classname: 'detail_info',
        isShow: true,
        type: ERROR,
        title: '操作提醒',
        buttons: btns,
        content: cnt,
      }).show();
    };
    /**
     * 重试弹框按钮
     */
    retryBtn = (param, req, cancelReq) => {
      const { callback } = param;
      const url = 'Truck/Truck/createSchedulingBill';
      const btns = [
        {
          key: 'retry',
          name: '重试',
          btnType: 'primary',
          cb: (dialog, btnKey) => {
            // 继续button置为loading状态
            dialog.changeBtnLoadingStatus(btnKey, true);
            postData(url, req, res => {
              dialog.changeBtnLoadingStatus(btnKey, false);
              dialog.handleHide();
              if (+res.data.errno === -152) this.retry(param, req, cancelReq);
              else callback(res);
            });
          },
        },
        {
          key: 'cancel',
          name: '取消',
          btnType: 'default',
          cb: dialog => {
            dialog.handleHide();
            // 这里"伪造"一个resData是为了在load/index.js文件中不会报错，尽量少的改别人的代码
            const resData = {
              res: {},
              req: {},
            };
            callback(resData, { type: 'retry_cancel', req: cancelReq });
          },
        },
      ];
      return btns;
    };
    /**
     * 重试弹框内容
     */
    retryCnt = () => {
      const notice = '无法连接平台车队系统，请稍后重试！';
      const specialTips =
        '发车批次已经创建成功，但无法连接平台车队系统发送调车任务，可能是网络原因或服务器问题导致的。';
      const explain1 = '点击[重试]按钮，可重新向平台车队系统发送调车任务。';
      const explain2 =
        '点击[取消]按钮，可回到批次详情页。您可线下联系平台车队服务人员手动创建调车单，联系电话：400-611-5656。';
      const cnt = (
        <div className="retry-content">
          <p className="notice">
            {notice}
            <p className="little-notice">{specialTips}</p>
          </p>
          <div className="checkinfo-detail">
            <p className="notice_title">操作说明：</p>
            <div className="detail-content">
              <p className="notice_list">
                <em>●</em>
                {explain1}
              </p>
            </div>
            <div className="detail-content">
              <p className="notice_list">
                <em>●</em>
                {explain2}
              </p>
            </div>
          </div>
        </div>
      );
      return cnt;
    };
    /*
    输入校验提示
  */
    validateCheck = validateInfo => {
      if (validateInfo.isPass === false) {
        const error = validateInfo.error || {};
        // eslint-disable-next-line no-nested-ternary
        let label = error.labelProps ? error.labelProps.children : error.otherProps ? error.otherProps.label : '';
        label = label || '';
        new PopUp(PopTip, {
          type: ERROR,
          content: error.msg || `[${label}]${validateInfo.errorInfo || '信息填写有误'}` || '信息填写有误',
        }).show();
        return false;
      }
      return true;
    };
    /*
    右侧数据空值校验
  */
    checkEmpty = (list, pageType) => {
      if (!list.length) {
        new PopUp(PopTip, {
          type: ERROR,
          content: config[pageType].tipsEmptyMessage || `${config[pageType].tipsContent}运单不可以为空`,
        }).show();
        return false;
      }
      return true;
    };

    buildPack = (pageType, value, otherProps, isSplit = true) => {
      console.log(otherProps);
      const columnList = config[pageType].submitData;
      // 往后台提交的数据不需要校验的列表
      const validates = columnList.checkColumn;
      // columnList.tableColumnList.push('od_link_detail')
      const data = this.formateData(value, columnList, validates, pageType, isSplit, otherProps);
      if (data === false) return false;
      const cardFormInfo = data.cardFormInfo || {};
      const tableInfo = data.tableInfo || [];
      // 有拆单信息的用拆单信息，覆盖原来的信息
      // tableInfo.forEach((item, index) => tableInfo[index] = { ...item, ...item.od_link_detail })
      const pack = {
        insured: value.tableInfo.isCheck ? '1' : '0',
        load_od_link_detail: tableInfo,
        car_batch: cardFormInfo.car_batch,
        load_b_pack_link_detail: value.packList,
        ...otherProps,
      };
      return { pack, data };
    };
    /**
     * 校验新增配载中间费用项的必填
     */
    checkFeeInfo = (value, otherProps) => {
      const requireFields = (otherProps && otherProps.require_fields) || []; // 必填字段的数组
      if (value.feeInfo !== undefined) {
        const firstFeeInfo = value.feeInfo[0] || [];
        const type = (otherProps && otherProps.type) || '';
        const feeHeader = (otherProps && otherProps.fee_header) || {};
        const isCheckPass = Object.keys(firstFeeInfo).every(item => {
          if (
            type === '1' &&
            requireFields.includes(item) &&
            typeof firstFeeInfo[item] !== 'number' &&
            !firstFeeInfo[item]
          ) {
            const title = (feeHeader[item] && feeHeader[item].title) || '';
            createTip(`[${title}]必填项，不能为空`, ERROR).show();
            return false;
          }
          return true;
        });
        if (!isCheckPass) return isCheckPass;
      }
      return true;
    };
    // 开通结算服务的特殊处理
    batchG7Confrim = async (data = {}) => {
      let confirmRef;
      if (!g7Done() || !data) {
        return {};
      }
      const closeConfirm = await confirmWithReasonSync({
        iconType: WARN, // icon
        tips: '如下字段会影响无车承运业务开票服务，请检查数据正确性。', // 顶部提示语
        noticeTitle: '',
        className: 'g7-batch-confrim',
        reason: (
          <div className="">
            <BatchConfirmForm data={data} ref={ref => (confirmRef = ref)} />
            <p style={{ color: 'red', marginTop: '15px' }}>
              注：已认证的批次，装车重量变动后无法同步修改发票上的重量，如需修改发票重量请联系客服同学进行修改
            </p>
          </div>
        ), // 具体原因
        btns: { confirm: 1, cancel: 1 },
        notAutoClose: true,
      });
      if (closeConfirm) {
        const confirmData = await confirmRef.getResData();
        if (!confirmData) {
          return false;
        }
        closeConfirm();
        return confirmData;
      }
    };
    /*
    装车操作
  */
    truckLoad = (parms, isCheck = false) => {
      // eslint-disable-line
      console.log('truckLoad', parms, this.props);
      let { pageType } = parms;
      const { truck_id, dr_id } = parms;

      const isPreLoadSetting = _.get(window, 'company_setting.task_auto_loading.checked') === 'no';
      const {
        btnKey,
        value,
        validateInfo,
        otherProps,
        callback,
        is_from_pay_mgr: needG7Confirm,
        pickOrder,
        isPreLoad = false,
        truckTeamSource,
        bBasicId,
      } = parms;
      if (value.isPack && ((!value.orderArrList.length && value.tableInfo.list.length) || value.orderArrList.length)) {
        value.tableInfo.list = value.orderArrList;
      }
      const hasFuelCardF = +_.get(value, 'feeInfo[0].b_fuel_card_f') > 0;
      if (!validateInfo.isPass) {
        // 有油卡费时油卡号才必填
        if (!(!hasFuelCardF && validateInfo.error.key === 'b_tr_payee_oil_card_no')) {
          if (!this.validateCheck(validateInfo)) return false;
        }
      }

      // throughDate 为透传信息 可能没有
      const checkRes = this.buildPack(pageType, value, otherProps);
      if (checkRes === false) return;
      const checkFeeInfo = this.checkFeeInfo(value, otherProps);
      console.log(checkRes, 'checkRescheckRes');
      if (checkFeeInfo === false) return;
      const { pack, data } = checkRes;
      const cardFormInfo = data.cardFormInfo || {};
      delete pack.g7_info;
      pack.fee_info = data.feeInfo;
      pack.route = cardFormInfo.route; // 选择车辆线路 @zhujunyan
      if (cardFormInfo.route && cardFormInfo.route.line_nodes) {
        pack.route = cardFormInfo.route.line_nodes;
      } else if (!cardFormInfo.route && cardFormInfo.route_line.line_nodes) {
        pack.route = cardFormInfo.route_line.line_nodes;
      }
      if (!pack.route || (Array.isArray(pack.route) && pack.route.length < 2) || +pack.route[0].company_id === -1) {
        createTip('【车辆线路】至少选择2个节点', ERROR).show();
        return;
      }
      // if (throughDate && throughDate.route && throughDate.route.length > 0) {
      //   pack.route = throughDate.route
      // }
      pack.route_id = ''; // 选择车辆线路，需要把线路id一同传过去
      if (cardFormInfo.route && cardFormInfo.route.id) {
        pack.route_id = cardFormInfo.route.id;
      } else if (!cardFormInfo.route && cardFormInfo.route_line.id) {
        pack.route_id = cardFormInfo.route_line.id;
      }
      pack.bsc_info = deleteProps(cardFormInfo, 'car_batch', 'route') || {};
      if (!pack.bsc_info.b_tr_team_name) {
        delete pack.bsc_info.b_tr_team_id;
        delete pack.bsc_info.b_tr_team_name;
      }
      console.log('pack.bsc_info', pack.bsc_info);
      pack.bsc_info.route_type = '0';
      if (cardFormInfo.route && cardFormInfo.route.line_type) {
        pack.bsc_info.route_type = cardFormInfo.route.line_type;
      } else if (!cardFormInfo.route && cardFormInfo.route_line.line_type) {
        pack.bsc_info.route_type = cardFormInfo.route_line.line_type;
      }
      const { truck_id: truckId } = otherProps;
      pack.bsc_info.truck_id = truckId;
      pack.bsc_info.truck_team_source = truckTeamSource;
      const extPara = { pickOrder, btnKey, truck_id, dr_id };
      if (!(this.props && this.props.basicId)) {
        if (isPreLoadSetting) {
          extPara.url = '/Truck/TruckPre/onComplete';
          pageType = 'pre_load';
        }
      } else if (+this.props.batchSt === 5 || +this.props.batchSt === 13 || +this.props.batchSt === 16) {
        extPara.url = '/Truck/TruckPre/onComplete';
        pageType = 'pre_load';
      } else {
        extPara.url = '/Truck/Truck/onComplete';
        pageType = 'truck';
      }
      extPara.b_basic_id = bBasicId;

      if (pack.fee_info) {
        // pack.fee_info.forEach((item, index) => {
        //   const route = pack.route.filter(t => t.company_id)
        //   if (+item.company_id !== +route[index].company_id) {
        //     routeFlag = true
        //   }
        // })
        // if (routeFlag) {
        //   this.checkRouteFee()
        //   return
        // }

        const disableTruckTotal =
          window.company_setting.calced_price_disabled &&
          window.company_setting.calced_price_disabled.value.includes('b_tr_f_total');
        if (disableTruckTotal) {
          const feeTotal = _.sumBy(
            data.feeInfo,
            item =>
              (+item.b_billing_f || 0) + (+item.b_fuel_card_f || 0) + (+item.b_receipt_f || 0) + (+item.b_arr_f || 0),
          );
          if (feeTotal !== +pack.bsc_info.truck_total) {
            const title =
              '现付运输费、现付油卡费、到付运输费、回付运输费的合计金额与配载价格计算出来的“合计运输费”不等！';
            const tips =
              '您已设置"通过价格计算的合计运输费不允许修改"。请调整各付款方式的运输费金额，保证“现付运输费+现付油卡费+到付运输费+回付运输费=合计运输费”。';
            commonErrorTip({ title, detail: [tips] });
            return;
          }
        }
      }
      extPara.pageType = pageType;
      extPara.batchSt = this.props.batchSt;
      if (isCheck) {
        return pack;
      }
      if (needG7Confirm) {
        this.batchG7Confrim(otherProps.g7_info).then(res => {
          if (res) {
            pack.bsc_info = {
              ...pack.bsc_info,
              ...res,
              g7_unload_t: undefined,
            };
            pack.g7_unload_t = res.g7_unload_t;
            this.sendReq(pageType, pack, true, callback, extPara);
          }
        });
      } else {
        this.sendReq(pageType, pack, true, callback, extPara);
      }
    };
    checkRouteFee = () => {
      new PopUp(PopTip, { type: ERROR, content: '车辆线路跟费用信息不匹配，请检查！' }).show();
    };
    /*
    短驳操作
   */
    shuttleLoad = parms => {
      const {
        pageType,
        btnKey,
        value,
        validateInfo,
        otherProps,
        callback,
        pickOrder,
        is_from_pay_mgr: needG7Confirm,
      } = parms;
      if (!this.validateCheck(validateInfo)) return false;
      // let { pack, data } = this.buildPack(pageType, value, otherProps)
      const checkRes = this.buildPack(pageType, value, otherProps);
      if (checkRes === false) return;
      const { pack, data } = checkRes;
      delete pack.g7_info;
      const cardFormInfo = data.cardFormInfo || {};
      const routeArr = [];
      if (undefined !== cardFormInfo.station && typeIs(cardFormInfo.station, 'array') && cardFormInfo.station.length) {
        routeArr.push({ company_id: cardFormInfo.station[0].key });
      }
      pack.route = routeArr;
      pack.fee_info = [{ company_id: otherProps.com_id || window.company_id, b_shuttle_f: cardFormInfo.b_shuttle_f }];
      pack.bsc_info = deleteProps(cardFormInfo, 'car_batch', 'station', 'b_shuttle_f') || {};
      const { truck_id: truckId } = otherProps;
      pack.bsc_info.truck_id = truckId;
      if (!this.checkEmpty(pack[config[pageType].listKey], pageType)) return false;

      if (needG7Confirm) {
        this.batchG7Confrim(otherProps.g7_info).then(res => {
          if (res) {
            pack.bsc_info = {
              ...pack.bsc_info,
              ...res,
              g7_unload_t: undefined,
            };
            pack.g7_unload_t = res.g7_unload_t;
            this.sendReq(pageType, pack, true, callback, { pickOrder, btnKey });
          }
        });
      } else {
        this.sendReq(pageType, pack, true, callback, { pickOrder, btnKey });
      }
    };
    // 提货配载生成运单（极速版）
    taskDeliveryLoad = async params => {
      const { pageType, btnKey, value, otherProps, orderType } = params;
      const _otherProps = Object.assign({ orderType }, otherProps);
      const { pack } = this.buildPack(pageType, value, _otherProps, { isPick: true, isSign: false });
      const taskLoadRef = _.get(params, 'value.taskLoadRef');
      if (taskLoadRef && typeof taskLoadRef.save === 'function') {
        params.pickOrder.setLoadingStatus(true);
        await taskLoadRef
          .save(btnKey, null, null, {
            loadQuery: {
              order: pack,
              loadType: _.get(params, 'otherProps.loadType'),
            },
            onSuccessCallback(response) {
              params.callback(response);
            },
          })
          .finally(() => {
            params.pickOrder.setLoadingStatus(false);
          });
      }
      // this.sendReq(pageTy  pe, req, true, callback, { pickOrder, btnKey, url, orderType });
    };
    /*
    送货操作 & 提货操作, 根据pageType进行区分
  */
    deliveryLoad = parms => {
      console.log(parms);
      const {
        pageType,
        btnKey,
        value,
        validateInfo,
        otherProps,
        callback,
        pickOrder,
        url,
        orderType,
        is_from_pay_mgr: needG7Confirm,
      } = parms;
      if (!this.validateCheck(validateInfo)) return false;
      if (
        pageType === 'delivery' &&
        ((!value.orderArrList.length && value.tableInfo.list.length) || value.orderArrList.length)
      ) {
        value.tableInfo.list = value.orderArrList;
      }
      // let { pack, data } = this.buildPack(pageType, value, otherProps)
      const _otherProps = Object.assign({ orderType }, otherProps);
      if (otherProps && otherProps.com_id) {
        _otherProps.company_id = _otherProps.com_id;
        delete _otherProps.com_id;
      }
      const { needCheckSumEqual, autoSplit } = otherProps;
      const checkRes = this.buildPack(pageType, value, _otherProps, { isPick: true, isSign: false });
      if (checkRes === false) return;
      const { pack, data } = checkRes;
      delete pack.g7_info;
      const newData = [];
      // 如果勾选了单票送货费必填，提示
      let checkKey = `${pageType}_f`;
      let checkFlag = false;
      let checkPass = true;
      let setKey = pageType;
      const tableData = value.tableInfo.list || [];
      let splitWay = data.cardFormInfo.fee_ave_type;
      splitWay = _.isEqual(splitWay, []) ? '' : splitWay;
      if (splitWay !== 'order' && splitWay !== 'custom') {
        const splitWayAllZero = !tableData.some(item => +item[splitWay]);
        if (splitWayAllZero) {
          return splitWayErr(splitWay, setKey !== 'pickup' ? '送货' : '提货');
        }
      }
      const validity = (key1, key2, text1, text2) => {
        let flag = false;
        const val = data.cardFormInfo[key2];
        if (val !== undefined) {
          const newKey = key2.split('b_').join(key1);
          const he = tableData.reduce((a, b) => a + (+b[newKey] || 0), 0);
          if (he && (+val || 0).toFixed(2) !== he.toFixed(2)) {
            flag = true;
            showInfo(ERROR, `顶部的“${t_cluster(text1)}”与右屏“${t_cluster(text2)}”的合计值不等！`);
            return flag;
          }
        }
        return flag;
      };
      let num = 0;
      let aflag = true;
      const { itemList = [] } =
        window.company_setting.b_f_total_formula && window.company_setting.b_f_total_formula.formulaDt[0];
      itemList.forEach(key => {
        const dom = parms.value.cardInfo[key];
        if (dom) {
          num += +dom.otherProps.defaultValue || 0;
        } else {
          aflag = false;
        }
      });
      let noEqualKey;
      // 未分摊时后端会做分摊
      if (setKey !== 'pickup') {
        const bDeliverFTotal = data.cardFormInfo.b_delivery_f;
        const deliveryFTotal = data.cardFormInfo.delivery_f_total;
        if (bDeliverFTotal !== undefined) {
          const bDeliverFTotalCalc = tableData.reduce((a, b) => a + (+b.b_delivery_f || 0), 0);
          if (bDeliverFTotalCalc && (+bDeliverFTotal || 0).toFixed(2) !== bDeliverFTotalCalc.toFixed(2)) {
            return showInfo(ERROR, '顶部的“送货费合计”与右屏“送货费”的合计值不等！');
          }
          if (needCheckSumEqual && !noEqualKey && (+bDeliverFTotal || 0) !== bDeliverFTotalCalc) {
            noEqualKey = '送货';
          }
        }
        const deliveryFTotalCalc = tableData.reduce((a, b) => a + (+b.od_delivery_f || 0), 0);
        if (deliveryFTotal !== undefined) {
          if (
            +deliveryFTotal &&
            deliveryFTotalCalc &&
            (+deliveryFTotal || 0).toFixed(2) !== deliveryFTotalCalc.toFixed(2)
          ) {
            return showInfo(ERROR, '顶部的“单票送货费合计”与右屏“单票送货费”的合计值不等！');
          }
        }
        const flag1 = validity('b_delivery_', 'b_yf_trans_f', '应付运输费', '应付送货运输费');
        if (flag1) return;
        const flag2 = validity('b_delivery_', 'b_spot_fee', '应付点位费', '应付送货点位费');
        if (flag2) return;
        const flag3 = validity('b_delivery_', 'b_upstairs_f', '应付上楼费', '应付送货上楼费');
        if (flag3) return;
        const flag4 = validity('b_delivery_', 'b_handling_f', '应付装卸费', '应付送货装卸费');
        if (flag4) return;
        const flag5 = validity('b_delivery_', 'b_trans_tax', '应付税费', '应付送货税费');
        if (flag5) return;
        const flag6 = validity('b_delivery_', 'b_info_f', '整车信息费', '送货整车信息费');
        if (flag6) return;
        if (aflag && itemList.length && +(bDeliverFTotal || 0) !== num) {
          return showInfo(
            ERROR,
            '无法保存，送货费合计与设置不符，可能是创建批次后修改了应付运输费合计公式，但此批次的送货费合计并未按照新公式计算导致的，请手动调整送货费保持合计与公式一致',
          );
        }
      } else {
        const pickupFTotal = data.cardFormInfo.pickup_f;
        const bPickupFTotal = data.cardFormInfo.b_pickup_f;
        if (pickupFTotal !== undefined) {
          const pickupFTotalCalc = tableData.reduce((a, b) => a + (+b.pickup_f || 0), 0);
          if (pickupFTotalCalc && (+pickupFTotal || 0).toFixed(2) !== pickupFTotalCalc.toFixed(2)) {
            return showInfo(ERROR, '顶部的“单票提货费合计”与右屏“单票提货费”的合计值不等！');
          }
        }
        if (bPickupFTotal !== undefined) {
          const bPickupFTotalCalc = tableData.reduce((a, b) => a + (+b.b_pickup_f || 0), 0);
          if (bPickupFTotalCalc && (+bPickupFTotal || 0).toFixed(2) !== bPickupFTotalCalc.toFixed(2)) {
            return showInfo(ERROR, '顶部的“提货费合计”与右屏“提货费”的合计值不等！');
          }
          if (needCheckSumEqual && !noEqualKey && (+bPickupFTotal || 0) !== bPickupFTotalCalc) {
            noEqualKey = '提货';
          }
        }
        const flag1 = validity('b_pickup_', 'b_yf_trans_f', '应付运输费', '应付提货运输费');
        if (flag1) return;
        const flag2 = validity('b_pickup_', 'b_spot_fee', '应付点位费', '应付提货点位费');
        if (flag2) return;
        const flag3 = validity('b_pickup_', 'b_upstairs_f', '应付上楼费', '应付提货上楼费');
        if (flag3) return;
        const flag4 = validity('b_pickup_', 'b_handling_f', '应付装卸费', '应付提货装卸费');
        if (flag4) return;
        const flag5 = validity('b_pickup_', 'b_trans_tax', '应付税费', '应付提货税费');
        if (flag5) return;
        const flag6 = validity('b_pickup_', 'b_info_f', '整车信息费', '提货整车信息费');
        if (flag6) return;
        if (aflag && itemList.length && +(bPickupFTotal || 0) !== num) {
          return showInfo(
            ERROR,
            '无法保存，提货费合计与设置不符，可能是创建批次后修改了应付运输费合计公式，但此批次的提货费合计并未按照新公式计算导致的，请手动调整提货费保持合计与公式一致',
          );
        }
      }
      if (needCheckSumEqual && noEqualKey) {
        confirmWithReason({
          title: '操作确认',
          tips: `顶部的“${noEqualKey}费”与右屏的“${noEqualKey}费”合计不一致！`, // 顶部提示语
          noticeTitle: '操作提示：',
          specialCause: [
            {
              msg: `点击“分摊并保存”：会按照顶部的“${noEqualKey}费”分摊并更新右屏运单的“${noEqualKey}”。如果您设置了“${noEqualKey}月结系数”，会同步更新右屏里指派来的运单的“月结”。`,
            },
            {
              msg: `点击“取消”：回到提货页面，可手动点击“分摊”按钮，或修改右屏运单的“${noEqualKey}货费”。`,
            },
          ], // 具体原因
          btnText: { confirm: '分摊并保存' },
          onConfirm: async cb => {
            cb();
            const orderData = await autoSplit();
            if (!orderData) {
              return;
            }
            if (
              pageType === 'delivery' &&
              ((!value.orderArrList.length && value.tableInfo.list.length) || value.orderArrList.length)
            ) {
              parms.value.orderArrList = orderData; // eslint-disable-line
            } else {
              parms.value.tableInfo.list = orderData; // eslint-disable-line
            }
            this.deliveryLoad(parms);
          },
        });
        return;
      }
      setKey === 'pickup' && (setKey = 'pick_up');
      const setting = window.company_setting[`${setKey}_field_setting`].value.filter(x => x.key === checkKey);
      // 送货费的key特殊
      pageType === 'delivery' && (checkKey = 'od_delivery_f');
      setting.length && setting[0].required && (checkFlag = true);
      pack.load_od_link_detail.forEach(item => {
        const newItem = item;
        if (checkFlag && checkPass && (!item[checkKey] || +item[checkKey] === 0)) {
          checkPass = false;
          showCheckFeeTips(pageType);
        }
        config.deliveryLoadCheck.forEach(checkboxKey => {
          if (typeIs(item[checkboxKey], 'object')) {
            newItem[checkboxKey] = item[checkboxKey].checked ? 1 : 0;
          }
        });
        newData.push(newItem);
      });
      if (!checkPass) return false;
      pack.load_od_link_detail = newData;
      const cardFormInfo = data.cardFormInfo || {};
      const devePack = pageType === 'delivery' && Array.isArray(value.packList) && value.packList.length === 0;
      if (devePack && !this.checkEmpty(pack[config[pageType].listKey], pageType)) return false;
      const feeKey = `b_${pageType}_f`;
      const basicFeeInfo = {
        company_id: otherProps.com_id || window.company_id,
        [feeKey]: cardFormInfo[feeKey],
        b_yf_trans_f: cardFormInfo.b_yf_trans_f,
        b_spot_fee: cardFormInfo.b_spot_fee,
        b_upstairs_f: cardFormInfo.b_upstairs_f,
        b_handling_f: cardFormInfo.b_handling_f,
        b_trans_tax: cardFormInfo.b_trans_tax,
        b_info_f: cardFormInfo.b_info_f,
      };
      const packFeeInfo =
        pageType === 'pickup'
          ? { ...basicFeeInfo, pickup_f_total: cardFormInfo.pickup_f_total }
          : { ...basicFeeInfo, delivery_f_total: cardFormInfo.delivery_f_total };
      pack.fee_info = [packFeeInfo];
      pack.bsc_info = deleteProps(cardFormInfo, 'car_batch', feeKey, 'pickup_f_total', 'delivery_f_total') || {};
      const { truck_id: truckId } = otherProps;
      pack.bsc_info.truck_id = truckId;
      if (pageType === 'pickup') {
        pack.is_check = 1;
      }
      let flag = false;
      /**
       * 张野要求：取首行的再加个逻辑，如果首行数据是指派过来的单子，取delivery_tr_team_id（pickup_tr_team_id），否则取com_id
       * 判断是指派过来单子 order_status = -10
       */
      if (pageType === 'delivery' || pageType === 'pickup') {
        let comIdKey;
        if (pageType === 'delivery') comIdKey = 'delivery_tr_team_id';
        if (pageType === 'pickup') comIdKey = 'pickup_tr_team_id';
        const rowInfo = tableData[0];
        let rowComId;
        if (rowInfo) {
          if (rowInfo.order_status && +rowInfo.order_status === -10) {
            rowComId = rowInfo[comIdKey];
          } else {
            rowComId = rowInfo.com_id;
          }
        }
        pack.company_id = rowComId || window.company_id;
        console.log(pack);
        if (pack.bsc_info.route) {
          const { route } = pack.bsc_info;
          if (route.line_nodes) {
            pack.route = route.line_nodes;
            pack.route_id = route.id;
            pack.bsc_info.route = route.line_nodes;
          } else {
            pack.route = route;
          }
        }
        const isRequired =
          value.cardInfo.route && value.cardInfo.route.labelProps ? value.cardInfo.route.labelProps.isRequired : false;
        if (isRequired && pack.route && pack.route[0] && +pack.route[0].company_id === -1) {
          flag = true;
        } else if (!isRequired && pack.route && pack.route[0] && +pack.route[0].company_id === -1) {
          pack.route = [];
          pack.bsc_info.route = [];
        }
      }
      if (flag) {
        createTip('【车辆线路】必填项，不能为空', ERROR).show();
        return;
      }
      if (needG7Confirm) {
        this.batchG7Confrim(otherProps.g7_info).then(res => {
          if (res) {
            pack.bsc_info = {
              ...pack.bsc_info,
              ...res,
              g7_unload_t: undefined,
            };
            pack.g7_unload_t = res.g7_unload_t;
            this.sendReq(pageType, pack, true, callback, { pickOrder, btnKey, url, orderType });
          }
        });
      } else {
        this.sendReq(pageType, pack, true, callback, { pickOrder, btnKey, url, orderType });
      }
    };
    /*
    签收操作
  */
    orderSign = async (pageType, key, val, validateInfo, otherProps, callback, pickOrder) => {
      const value = val;
      if (!this.validateCheck(validateInfo)) return false; // 检测签收人是否为空
      const submitColumn = config[pageType].submitData;
      if (value.isPack && ((!value.orderArrList.length && value.tableInfo.list.length) || value.orderArrList.length)) {
        value.tableInfo.list = value.orderArrList;
      }
      const data = this.formateData(value, submitColumn, undefined, pageType);
      if (data === false) return false;
      const cardFormInfo = data.cardFormInfo || {};
      const msgTypeList = [];
      const pack = {
        source: 'batch',
        od_link_detail: data.tableInfo || [],
        company_id: otherProps.company_id || '',
        msgTypeList,
        ...otherProps,
        b_pack_link_detail: value.packList,
      };
      pack.sign_info = cardFormInfo;
      pack.sign_info.sign_t = pack.sign_info.sign_t || moment().format('YYYY-MM-DD HH:mm:ss');
      pack.sign_info.sign_mgr_id = pack.sign_info.sign_mgr_id || window.user_id;
      const checkListArr = value.packList ? value.packList : pack[config[pageType].listKey];
      if (!this.checkEmpty(checkListArr, pageType)) return false;
      const tableQuery = pickOrder.getTableQuery() || {};
      if (data.tableForm.consignor) {
        msgTypeList.push('BUS_SIGN_SEND'); // 签收发货人
        const parms = [
          {
            order_ids: [],
            tpl_ids: [],
          },
        ];
        for (const item of data.tableInfo) {
          parms[0].order_ids.push(item.od_link_id);
        }
        await actions.orderServer.msgModel(['BUS_SIGN_SEND']).then(res => {
          if (res.type === 'API_SUCCESS' && res.data.errno === 0) {
            const _data = res.data.res.data.templateList;
            for (const item of _data) {
              parms[0].tpl_ids.push(item.tpl_id);
            }
          }
        });
        if (parms[0] && parms[0].order_ids.length > 0 && parms[0].tpl_ids.length > 0) {
          // 短信模板的参数
          const templateParam = {
            sign: 'Order',
            req: {
              category: tableQuery.category,
              tab: tableQuery.tab,
              orderIds: parms[0].order_ids,
              tplIds: parms[0].tpl_ids,
              typeList: msgTypeList,
            },
            url: 'Basic/ShortMessage/checkOrderMsgHistory',
          };
          const msgTemplate = await checkMsgHistory(templateParam);
          if (!msgTemplate) return;
          if (msgTemplate && msgTemplate.tpl_ids) parms[0].tpl_ids = msgTemplate.tpl_ids;
          if (msgTemplate.op === 'skip') parms[0].order_ids = msgTemplate.list;
          pack.msg_param = parms;
        }
        // const canSend = await checkSend(parms)
        // if (!canSend) return
      }
      this.sendReq(pageType, pack, true, callback, { pickOrder, btnKey: key });
    };
    deliveryBatchDispatch = parms => {
      const { pageType, tips, value, validateInfo, opType, callback } = parms;
      if (!this.validateCheck(validateInfo)) return false;
      const submitColumn = config[pageType].submitData;
      const data = this.formateData(value, submitColumn, undefined, pageType);
      if (data === false) return false;
      const tableData = value.tableInfo.list;
      const { cardFormInfo } = data;
      const batchKey = tips === '送货' ? 'delivery_batch' : 'pickup_batch';
      cardFormInfo.b_dr_id = _.get(value.cardInfo, 'dr_name.otherProps.defaultSelected[0].id');
      const operationType = pageType;
      const pack = {
        is_check: true,
        op_type: opType,
        dispatch_infos: tableData.map(v => {
          const drName = _.isEqual(v.b_dr_name, ['']) ? '' : v.b_dr_name;
          const drPhone = _.isEqual(v.b_dr_phone, ['']) ? '' : v.b_dr_phone;
          return {
            b_link_id: v.b_link_id,
            b_delivery_f: v.b_delivery_f,
            [batchKey]: v[batchKey],
            dr_name: _.get(drName, '[0].name', drName) || cardFormInfo.dr_name,
            dr_phone: _.get(drPhone, '[0].tel', drPhone) || cardFormInfo.dr_phone,
            dr_id: _.get(drName, '[0].id', cardFormInfo.b_dr_id),
            remark: v.b_remark,
            tr_num: v.b_tr_num,
            tr_id: v.b_tr_id,
          };
        }),
      };
      if (!pack.dispatch_infos.length) {
        return showInfo(ERROR, '批次不可为空');
      }
      const failedDetail = [];
      pack.dispatch_infos.forEach(item => {
        if (!item.dr_name) {
          failedDetail.push({
            number: item.delivery_batch,
            msg: '派单司机不可为空',
          });
        }
        if (!item.dr_phone) {
          failedDetail.push({
            number: item.delivery_batch,
            msg: '派单司机手机号不可为空',
          });
        }
      });
      if (failedDetail.length) {
        return opTips({
          resData: {
            errno: -100,
            res: {
              failed_detail: failedDetail,
            },
          },
          opType: operationType,
          orderType: '批次',
        });
      }

      const header = {
        [batchKey]: { title: `${tips}批次`, display: 'show', width: 110 },
        tr_num: { title: '车牌号', display: 'show' },
        dr_name: { title: '司机', display: 'show' },
        dr_phone: { title: '司机手机号', display: 'show' },
      };
      const url = config[pageType].completeUrl;
      new PopUp(BatchOp, {
        title: `批量${tips}派单`,
        tips: `${tips}派单`,
        header,
        data: pack.dispatch_infos,
        requestUrl: url,
        getPara: dataList => ({
          dispatch_infos: dataList,
          op_type: opType,
        }),
        finishCallback: () => {
          setTimeout(callback, 500);
        },
        escBind: false,
      }).show();
    };
    batchDispatchDriver = parms => {
      const { btnKey, value, type, callback, pickOrder } = parms;
      // if (!this.validateCheck(validateInfo)) return false
      const pageType = 'batchDispatchDriver';
      const submitColumn = config[pageType].submitData;
      const data = this.formateData(value, submitColumn, undefined, pageType);
      if (data === false) return false;
      const tableData = value.tableInfo.list;
      const { cardFormInfo } = data;
      cardFormInfo.tr_id = _.get(value.cardInfo, 'tr_num.otherProps.defaultSelected[0].id');
      cardFormInfo.tr_ext_id = _.get(value.cardInfo, 'tr_num_ext.otherProps.defaultSelected[0].id');
      cardFormInfo.b_dr_id = _.get(value.cardInfo, 'dr_name.otherProps.defaultSelected[0].id');
      cardFormInfo.dr_info_id = _.get(value.cardInfo, 'dr_name.otherProps.defaultSelected[0].dr_id');
      cardFormInfo.dr_assistant_id = _.get(value.cardInfo, 'dr_assistant_name.otherProps.defaultSelected[0].id');
      cardFormInfo.dr_assistant_info_id = _.get(
        value.cardInfo,
        'dr_assistant_name.otherProps.defaultSelected[0].dr_id',
      );
      const getVal = (val, key, cardVal) => {
        let rowVal = _.isEqual(val, ['']) ? '' : val;
        rowVal = rowVal === '0' ? '' : rowVal;
        return _.get(rowVal, [0, key], rowVal) || cardVal;
      };
      const pack = {
        is_check: true,
        dispatch_infos: tableData.map(v => ({
          batch_id: v.b_basic_id,
          dr_name: getVal(v.b_dr_name, 'name', cardFormInfo.dr_name),
          dr_phone: getVal(v.b_dr_phone, 'tel', cardFormInfo.dr_phone),
          dr_id: getVal(v.b_dr_id, 'id', cardFormInfo.b_dr_id),
          dr_info_id: getVal(v.b_dr_info_id, 'dr_id', cardFormInfo.dr_info_id),
          dr_assistant_name: getVal(v.b_dr_assistant_name, 'name', cardFormInfo.dr_assistant_name),
          dr_assistant_phone: getVal(v.b_dr_assistant_phone, 'tel', cardFormInfo.dr_assistant_phone),
          dr_assistant_id: getVal(v.dr_assistant_id, 'id', cardFormInfo.dr_assistant_id, false),
          dr_assistant_info_id: getVal(v.dr_assistant_info_id, 'dr_id', cardFormInfo.dr_assistant_info_id),
          tr_num: getVal(v.b_tr_num, 'tr_num', cardFormInfo.tr_num),
          tr_id: getVal(v.b_tr_id, 'id', cardFormInfo.tr_id),
          tr_num_ext: getVal(v.b_tr_num_ext, 'tr_num', cardFormInfo.tr_num_ext),
          tr_ext_id: getVal(v.b_tr_ext_id, 'id', cardFormInfo.tr_ext_id),
          car_batch: v.car_batch,
        })),
      };
      if (!pack.dispatch_infos.length) {
        return showInfo(ERROR, '批次不可为空');
      }
      const failedDetail = [];
      pack.dispatch_infos.forEach(item => {
        if (!item.tr_num) {
          failedDetail.push({
            number: item.car_batch,
            msg: '车牌号不可为空',
          });
        }
        if (!item.dr_name) {
          failedDetail.push({
            number: item.car_batch,
            msg: '主驾司机不可为空',
          });
        }
        if (!item.dr_phone) {
          failedDetail.push({
            number: item.car_batch,
            msg: '主驾手机号不可为空',
          });
        }
      });
      let url = '/cmm-batch/batch/assign/driver';
      let opType = '指派司机';
      if (type === 'modify') {
        url = `${url}/update`;
        opType = `修改${opType}`;
      }
      if (failedDetail.length) {
        return opTips({
          resData: {
            errno: -100,
            res: {
              failed_detail: failedDetail,
            },
          },
          opType,
          orderType: '运输任务',
          sucCallback: callback,
          failedCallback: callback,
        });
      }

      pickOrder.setRightButtonLoadingStatus(btnKey);
      postJAVA(url, pack.dispatch_infos).then(res => {
        pickOrder.setRightButtonLoadingStatus(btnKey, false);
        opTips({
          resData: res,
          opType,
          orderType: '运输任务',
          sucCallback: callback,
          failedCallback: callback,
        });
      });
    };
    // 派单
    orderDispatch = parms => {
      const { pageType, btnKey, value, validateInfo, opType, callback, pickOrder } = parms;
      if (!this.validateCheck(validateInfo)) return false;
      const submitColumn = config[pageType].submitData;
      const data = this.formateData(value, submitColumn, undefined, pageType);
      if (data === false) return false;
      const tableData = data.tableInfo;
      const { cardFormInfo } = data;
      const operationType = opType === 'update' ? 'updateDispatch' : 'dispatch';
      const pack = {
        is_check: true,
        op_type: opType,
        dispatch_infos: tableData.map(v => {
          const drName = _.isEqual(v.dispatch_dr_name, ['']) ? '' : v.dispatch_dr_name;
          const drPhone = _.isEqual(v.dispatch_dr_phone, ['']) ? '' : v.dispatch_dr_phone;
          console.log('drName =', drName, cardFormInfo);
          return {
            od_link_id: v.od_link_id,
            od_basic_id: v.od_basic_id,
            order_num: v.order_num,
            dispatch_dr_id: _.get(drName, '[0].id', drName) || cardFormInfo.dr_id,
            dispatch_dr_name: _.get(drName, '[0].name', drName) || cardFormInfo.dr_name,
            dispatch_dr_phone: _.get(drPhone, '[0].tel', drPhone) || cardFormInfo.dr_phone,
            dispatch_remark: v.dispatch_remark || '',
            dispatch_f: v.dispatch_f || 0,
            inner_remark: v.inner_remark || '',
            dispatch_reserved_t: v.dispatch_reserved_t,
          };
        }),
      };
      if (!this.checkEmpty(pack[config[pageType].listKey], pageType)) return false;
      const failedDetail = [];
      pack.dispatch_infos.forEach(item => {
        if (!item.dispatch_dr_name || !item.dispatch_dr_name[0]) {
          failedDetail.push({
            number: item.order_num,
            msg: '配安司机不可为空',
          });
        }
        if (!item.dispatch_dr_phone || !item.dispatch_dr_phone[0]) {
          failedDetail.push({
            number: item.order_num,
            msg: '配安司机手机号不可为空',
          });
        }
      });
      if (failedDetail.length) {
        return opTips({
          resData: {
            errno: -100,
            res: {
              failed_detail: failedDetail,
            },
          },
          opType: operationType,
        });
      }
      this.sendReq(pageType, pack, true, callback, { pickOrder, btnKey, pageType: operationType });
    };
    bindDevice = params => {
      const { pageType, value, validateInfo, btnKey, callback, pickOrder } = params;
      if (!this.validateCheck(validateInfo)) return false;
      const submitColumn = config[pageType].submitData;
      const data = this.formateData(value, submitColumn, submitColumn.checkColumn, pageType);
      if (data === false) return false;
      let tableData = data.tableInfo;
      if (!tableData.length) {
        return showInfo(ERROR, '请选择要绑定设备的运单！');
      }
      tableData = tableData.map(item => ({
        ...item,
        device_id: item.device_id ? item.device_id.filter(i => i).map(i => i.id || i) : [],
      }));
      const pack = {
        order_list: tableData,
      };
      this.sendReq(pageType, pack, true, callback, { pickOrder, btnKey, pageType: '绑定设备', formate: false });
    };
    // 完成打包
    completePack = parms => {
      const { pageType, btnKey, value, validateInfo, opType, callback, pickOrder, otherProps } = parms;
      if (!this.validateCheck(validateInfo)) return false;
      const submitColumn = config[pageType].submitData;
      const data = this.formateData(value, submitColumn, undefined, pageType);
      if (data === false) return false;
      const tableData = data.tableInfo;
      const load_od_link_detail = [];
      const { cardFormInfo } = data;
      tableData.forEach(v => {
        load_od_link_detail.push({ od_link_id: v.od_link_id });
      });
      const operationType = opType === 'update' ? 'updatePack' : 'disPack';
      console.log(cardFormInfo, 'cardFormInfo');
      const pack = {
        insured: 0,
        b_link_id: otherProps.b_link_id,
        car_batch: cardFormInfo.car_batch || '',
        old_car_batch: cardFormInfo.car_batch || '',
        load_od_link_detail,
        company_id: otherProps.com_id || '',
        bsc_flag: 0,
        bsc_info: {
          remark: cardFormInfo.remark,
          truck_t: cardFormInfo.truck_t,
          mgr_id: cardFormInfo.mgr_id,
        },
      };
      if (!this.checkEmpty(pack[config[pageType].listKey], pageType)) return false;
      const failedDetail = [];
      // pack.dispatch_infos.forEach(item => {

      // })
      if (failedDetail.length) {
        return opTips({
          resData: {
            errno: -100,
            res: {
              failed_detail: failedDetail,
            },
          },
          opType: operationType,
        });
      }
      this.sendReq(pageType, pack, true, callback, { pickOrder, btnKey, pageType: operationType });
    };
    reservationAssign = params => {
      const { pageType, btnKey, value, validateInfo, callback, pickOrder } = params;
      if (!this.validateCheck(validateInfo)) return false;
      const submitColumn = config[pageType].submitData;
      const data = this.formateData(value, submitColumn, undefined, pageType);
      if (data === false) return false;
      const tableData = data.tableInfo;
      const { cardFormInfo } = data;
      const operationType = 'assign';
      const topCarrierId = cardFormInfo.carrier_id && cardFormInfo.carrier_id.id;
      if (!tableData.length) {
        return showInfo(ERROR, '请选择要分配的订单！');
      }
      const pack = {
        force: false,
        // op_type: opType,
        assign_infos: tableData.map(v => {
          const carrierId = _.isEqual(v.carrier_id, ['']) ? '' : v.carrier_id;
          return {
            reservation_num: v.reservation_num,
            id: v.id,
            carrier_id: _.get(carrierId, '[0].id', carrierId) || topCarrierId,
          };
        }),
      };
      if (!this.checkEmpty(pack[config[pageType].listKey], pageType)) return false;
      const failedDetail = [];
      pack.assign_infos.forEach(item => {
        if (!item.carrier_id) {
          failedDetail.push({
            number: item.reservation_num,
            msg: '承运商不可为空',
          });
        }
      });
      if (failedDetail.length) {
        return opTips({
          resData: {
            errno: -100,
            res: {
              failed_detail: failedDetail,
            },
          },
          opType: operationType,
          orderType: '订单',
        });
      }
      this.sendReq(pageType, pack, true, callback, { pickOrder, btnKey, pageType: operationType });
    };
    // 中转付款方式验证
    transCheckPayment = (dataList, paymentKey) => {
      // 付款方式 只 传值
      let flag = true;
      const newDataList = [];
      dataList.forEach(item => {
        const newData = item;
        const payment = item[paymentKey];
        if (
          payment === undefined ||
          payment === 'pay_none' ||
          payment === '' ||
          payment.length === 0 ||
          (payment.length && payment[0].key === 'pay_none')
        ) {
          new PopUp(PopTip, {
            type: ERROR,
            content: '付款方式不得为空',
          }).show();
          flag = false;
          return;
        }
        if (Array.isArray(payment) && payment.length) {
          newData[paymentKey] = payment[0].key;
        }
        newDataList.push(newData);
      });
      if (flag) return newDataList;
      return flag;
    };
    /*
    中转操作
    // ext ： 其他字段
          firstCheckCallback: 中转接口is_check为true时，后台返回结果后的处理
  */
    orderTrans = async (pageType, key, value, validateInfo, otherProps, callback, isModify, ext, pickOrder) => {
      const _this = this;
      if (!this.validateCheck(validateInfo)) return false;
      let url = '/Order/Trans/transHandle';
      const submitColumn = config[pageType].submitData;
      // 往后台提交的数据不需要校验的列表
      const noNeedCheck = submitColumn.noNeedCheck || [];
      const validates = {};
      const truckData = { ...value.truckData };
      // 校验列表
      const validateList = (submitColumn.tableColumnList || []).filter(x => noNeedCheck.indexOf(x) === -1);
      validateList.forEach(item => {
        validates[item] = ['float'];
      });
      const data = this.formateData(value, submitColumn, validates, 'trans', false);
      if (data === false) return false;
      const cardFormInfo = data.cardFormInfo || {};
      let dataList = [];
      let paymentKey = +otherProps.trans_type === 1 ? 'trans_pay_mode' : 'inner_trans_pay_mode';
      let prefix = +otherProps.trans_type === 1 ? '' : 'inner_';
      const moreGoodsFilter = ['trans_w', 'trans_v'];
      if (+otherProps.trans_type === 3) {
        paymentKey = 'point_trans_pay_mode';
        prefix = 'point_';
      }
      data.tableInfo.forEach(item => {
        const newItem = item;
        if (item.trans_billing_method && typeIs(item.trans_billing_method, 'array')) {
          newItem.trans_billing_method = newItem.trans_billing_method[0].key;
        }
        if (_.get(window, 'company_setting.weight_unit.value') === 'T' && prefix !== 'inner_') {
          let transWDetail = newItem[`${prefix}trans_w_detail`];
          if (!typeIs(transWDetail, 'array')) {
            transWDetail = [transWDetail];
          }
          // && (!typeIs(newItem[`${prefix}trans_w_detail`], 'array') || (typeIs(newItem[`${prefix}trans_w_detail`], 'array') && newItem[`${prefix}trans_w_detail`].length === 1)))
          dataList.push({ ...newItem, [`${prefix}trans_w_detail`]: transWDetail.map(w => switchWeight(w, true)) });
        } else {
          dataList.push(newItem);
        }
      });
      // 多货时需要计算网点中转体积重量体积和
      if (+otherProps.trans_type === 1 || +otherProps.trans_type === 3) {
        dataList = dataList.map(item => {
          const newItem = item;
          moreGoodsFilter.forEach(gKey => {
            const field = `${prefix}${gKey}`;
            const fieldDetail = `${prefix}${gKey}_detail`;
            if (!newItem[fieldDetail]) {
              newItem[fieldDetail] = [0];
            } else if (typeIs(item[fieldDetail], 'string')) {
              newItem[fieldDetail] = newItem[fieldDetail].split('，');
            } else if (typeIs(item[fieldDetail], 'number')) {
              newItem[fieldDetail] = [item[fieldDetail]];
            } else if (!typeIs(item[fieldDetail], 'array')) {
              newItem[fieldDetail] = [0];
            }
            newItem[field] = newItem[fieldDetail].map(v => +v).reduce((a, b) => +a + +b, 0);
          });
          return newItem;
        });
      }
      let checkRes = this.transCheckPayment(dataList, paymentKey);
      if (checkRes === false) return false;
      const msgTypeList = [];
      const notEquals = [];
      checkRes = checkRes.map(iitem => {
        // selectDrop 选的值 的格式为 [{}] :(
        const item = iitem;
        item[`${prefix}trans_f_paid`] = item[`${prefix}trans_f`] && item[`${prefix}trans_f`].checked ? 1 : 0;
        item[`${prefix}trans_f`] = typeIs(item[`${prefix}trans_f`], 'object')
          ? item[`${prefix}trans_f`].text || 0
          : item[`${prefix}trans_f`];
        item[`${prefix}trans_settle_money`] = typeIs(item[`${prefix}trans_settle_money`], 'object')
          ? item[`${prefix}trans_settle_money`].text || 0
          : item[`${prefix}trans_settle_money`];
        if (typeIs(item[`${prefix}trans_f`], 'object')) {
          item[`${prefix}trans_f`] = 0;
        }
        // 只要中转费付款方式不是免费，则外部中转费合计必须=现付中转费+到付中转费+月结中转费+欠付中转费+回付中转费+货款扣中转费+货到打卡中转费
        if (!prefix && _.get(iitem[paymentKey], '[0].key') !== 'pay_free') {
          const pay = getTotalPay('', item);
          if (+(pay || 0) !== +(item.trans_f || 0)) {
            notEquals.push({
              msg: '外部中转费合计与中转费付款方式的合计值不相等',
              number: item.order_num,
            });
          }
        }
        const transArrStn = _.get(item, 'trans_arr_stn[0]', item.trans_arr_stn); // 如果没有trans_arr_stn transArrStn会是undefined所以不会影响其他地方
        _.set(item, 'trans_arr_stn', transArrStn);
        return item;
      });
      if (notEquals.length) {
        // opTips({
        //   resData: {
        //     res: {
        //       failed_detail: notEquals
        //     }
        //   },
        //   opType: 'trans_1'
        // })
        // ext && ext.failedCallback && ext.failedCallback()
        // return
      }
      const pack = {
        [config[pageType].listKey]: checkRes || [],
        // msg_info: data.tableForm || {},
        msgTypeList,
        trans_batch: cardFormInfo.trans_batch,
        fee_info: data.feeInfo || [],
        load_order: data.tableForm.load_order || false,
        ...otherProps,
      };
      if (cardFormInfo !== undefined && Object.keys(cardFormInfo).length) {
        const carrierInfo = cardFormInfo.carrier_id;
        // cardFormInfo = deleteProps(cardFormInfo, 'carrier_id')
        cardFormInfo.carrier_id = carrierInfo.id;
        cardFormInfo.dn_com_id = carrierInfo.slave_pid || 0;
        pack.com = deleteProps(cardFormInfo, 'trans_batch') || {};
      }
      const list = pack[config[pageType].listKey];

      if (!list.length) {
        showInfo(ERROR, '请选择要中转的运单');
        return false;
      }
      if (isModify) {
        url = +otherProps.trans_type === 1 ? '/Order/Trans/modifyTrans' : '/Order/Trans/modifyPointTrans';
        config[pageType].tipsContent = '中转修改';
      }
      // 增加 合计运费大于实际运费，请确定是否要进行中转
      // this.transTip(pack.orders, pageType)

      let _actualPrice = 0; // 合计运费
      let _transF = 0; // 合计中转费
      pack.orders.forEach(item => {
        _actualPrice += Number(item.actual_price) || 0;
        _transF += Number(item.trans_f || item.inner_trans_freight_f || item.point_trans_freight_f) || 0;
      });

      const _setting = window.company_setting;
      const transInTip = _setting.trans_in_tip.checked || false; // 员工中转费大于实际运费提醒
      const tableQuery = (pickOrder && pickOrder.getTableQuery()) || {};
      const _tips = '合计运费大于合计中转费，请确定是否要进行中转';
      if (data.tableForm.sendmsg && data.tableForm.sendmsg.length) {
        // 短信发送验证
        data.tableForm.sendmsg.forEach(v => msgTypeList.push(v));
        const parms = [
          {
            order_ids: [],
            tpl_ids: [],
          },
        ];
        for (const item of data.tableInfo) {
          parms[0].order_ids.push(item.od_link_id);
        }
        const templateListMap = {};
        await actions.orderServer.msgModel(['BUS_TRANSFER_RECEIVE', 'BUS_TRANSFER_SEND']).then(res => {
          if (res.type === 'API_SUCCESS' && res.data.errno === 0) {
            const _data = res.data.res.data.templateList;
            for (const item of _data) {
              const itemType = item.type;
              templateListMap[item.tpl_id] = item;
              if (
                itemType === 'BUS_TRANSFER_RECEIVE' &&
                data.tableForm.sendmsg.findIndex(v => v === 'BUS_TRANSFER_RECEIVE') > -1
              ) {
                parms[0].tpl_ids.push(item.tpl_id);
              }
              if (
                itemType === 'BUS_TRANSFER_SEND' &&
                data.tableForm.sendmsg.findIndex(v => v === 'BUS_TRANSFER_SEND') > -1
              ) {
                parms[0].tpl_ids.push(item.tpl_id);
              }
            }
          }
        });
        // 短信模板的参数
        const templateParam = {
          sign: 'Order',
          req: {
            category: tableQuery.category,
            tab: tableQuery.tab,
            orderIds: parms[0].order_ids,
            tplIds: parms[0].tpl_ids,
            typeList: data.tableForm.sendmsg,
          },
          url: 'Basic/ShortMessage/checkOrderMsgHistory',
        };
        const msgTemplate = await checkMsgHistory(templateParam);
        if (!msgTemplate) return;
        if (msgTemplate && msgTemplate.tpl_ids) {
          parms[0].tpl_ids = msgTemplate.tpl_ids;
          const newTypeList = msgTemplate.tpl_ids.map(item => templateListMap[item].type);
          pack.msgTypeList = newTypeList;
        }
        if (msgTemplate.op === 'skip') parms[0].order_ids = msgTemplate.list;
        pack.msg_param = parms;
        const canSend = await checkSend(parms);
        if (!canSend) return;
      }
      if (truckData && truckData.value) {
        const truckForm = this.truckLoad(truckData, true);
        if (!truckForm) {
          return;
        }
        pack.truckForm = truckForm;
      }
      if (_transF > _actualPrice && pageType === 'trans_2' && transInTip) {
        ext.resetLoadingFunc && ext.resetLoadingFunc();
        const popup = new PopUp(ShowInfo, {
          isShow: true,
          type: INFO,
          content: _tips,
          autoDestroy: true,
          title: '中转确定',
          simpleMode: {
            confirm: thisDialog => {
              thisDialog.changeBtnLoadingStatus('confirm', true);
              _this.transContinue(
                pack,
                otherProps,
                pageType,
                () => {
                  callback && callback();
                  thisDialog.handleHide();
                },
                url,
                ext,
                key,
                pickOrder,
              );
            },
            cancel: thisDialog => {
              thisDialog.handleHide();
              return false;
            },
          },
          closable: true,
          // maskClosable: true,
        });
        popup.show();
      } else {
        _this.transContinue(pack, otherProps, pageType, callback, url, ext, key, pickOrder);
      }
    };
    transContinue = (_pack, otherProps, pageType, callback, url, ext, key, pickOrder) => {
      const newData = [];
      const pack = _pack;
      // 如果勾选了中转费必填，提示
      let checkKey = 'trans_freight_f';
      let setKey = 'outer_trans';
      let checkFlag = false;
      let checkPass = true;
      let payModeField = 'trans_pay_mode';
      if (pageType === 'trans_2') {
        checkKey = 'inner_trans_freight_f';
        setKey = 'inner_trans';
        payModeField = 'inner_trans_pay_mode';
      }
      if (pageType === 'trans_3') {
        checkKey = 'point_trans_freight_f';
        setKey = 'point_trans';
        payModeField = 'point_trans_pay_mode';
      }
      const setting = window.company_setting[`${setKey}_field_setting`].value.filter(x => x.key === checkKey);
      setting.length && setting[0].required && (checkFlag = true);
      const arrivalRequire = window.company_setting.outer_trans_field_setting.value.filter(x => x.key === 'arrival');
      let checkArrival = false;
      if (pageType === 'trans_1' && arrivalRequire && arrivalRequire[0] && arrivalRequire[0].required) {
        checkArrival = true;
      }
      // 校验送货方式--中转送货值必填
      const checkDelivery = pageType === 'trans_1';
      const checkTransDeliveryList = (window.company_setting.trans_delivery_f_must || {}).values || [];
      pack.orders.forEach(item => {
        // 免费时忽略必填校验
        const isFree = item[payModeField] === 'pay_free';
        if (checkFlag && checkPass && (!item[checkKey] || +item[checkKey] === 0) && !isFree) {
          checkPass = false;
          showCheckFeeTips(pageType);
          return;
        }

        // 外部中转中转送货费校验
        if (
          checkPass &&
          checkDelivery &&
          item.delivery_mode &&
          checkTransDeliveryList.includes(item.delivery_mode) &&
          (item.trans_delivery_f === undefined || item.trans_delivery_f === '' || +item.trans_delivery_f === 0)
        ) {
          checkPass = false;
          showCheckFeeTips('trans_delivery', EMPTY_CHECK_TIPS, '需送货时，[中转送货费]必填！');
          return;
        }
        // 中转到站必填
        if (checkPass && checkArrival && !item.trans_arr_stn) {
          checkPass = false;
          showCheckFeeTips('arrival', EMPTY_CHECK_TIPS);
          return;
        }

        const newItem = item;
        let transSettleKey = +otherProps.trans_type === 1 ? 'trans_settle_money' : 'inner_trans_settle_money';
        transSettleKey = +otherProps.trans_type === 3 ? 'point_trans_settle_money' : transSettleKey;
        if (typeIs(item[transSettleKey], 'object')) {
          const transSettle = item[transSettleKey] || {};
          newItem[transSettleKey] = transSettle.trans_settle_money || '';
          // 当 用户可以编辑时 `${transSettleKey}_paid` 取 `${transSettleKey}_checked`
          newItem[`${transSettleKey}_paid`] = transSettle.checked || newItem[`${transSettleKey}_checked`] ? 1 : 0;
        }
        // 没有勾选的情况下默认为0
        newItem[`${transSettleKey}_paid`] =
          newItem[`${transSettleKey}_paid`] || newItem[`${transSettleKey}_checked`] || 0;
        newData.push(newItem);
      });
      if (!checkPass) return;
      pack.orders = newData;
      let pre = +otherProps.trans_type === 1 ? '' : 'inner_';
      pre = +otherProps.trans_type === 3 ? 'point_' : pre;
      if (pre && !checkTotalTrans(pre, pack.orders)) {
        return;
      }
      if (otherProps && otherProps.transTotalSet && !checkTransTotalSum(pack.orders, otherProps.transTotalSet, pre))
        return;
      this.sendReq(pageType, pack, true, callback, Object.assign({ url, btnKey: key, pickOrder }, ext));
    };
    // 如果启用垫付费货款管理，那么员工中转保存校验“中转垫付费”等于原始运单的“开单垫付费” --cuimb
    checkPayAdv = (value, otherProps) => {
      const errArr = [];
      let chkResult = true;
      if (
        otherProps.enable_adv_pay_mgt &&
        value &&
        value.tableInfo &&
        value.tableInfo.list &&
        +otherProps.trans_type === 2
      ) {
        const tabList = value.tableInfo.list;
        for (const trObj of tabList) {
          if (trObj.co_pay_adv !== trObj.inner_trans_pay_adv) {
            chkResult = false;
            errArr.push(trObj.order_num);
          }
        }
        if (errArr.length > 0) {
          new PopUp(PopTip, {
            type: ERROR,
            content: `您启用了垫付费货款管理功能，${errArr.join(',')}运单中转垫付费需等于开单垫付费`,
          }).show();
        }
      }
      return chkResult;
    };

    /*
    出库单保存
  */
    outWMSLoad = (parms, isCheck = false) => {
      // eslint-disable-line
      const { pageType } = parms;
      const { btnKey, value, validateInfo, otherProps, callback, pickOrder, isPreLoad = false } = parms;
      // throughDate 为透传信息 可能没有
      if (!this.validateCheck(validateInfo)) return false;
      const checkRes = this.buildPack(pageType, value, otherProps, false);
      if (checkRes === false) return;
      const checkFeeInfo = this.checkFeeInfo(value, otherProps);
      if (checkFeeInfo === false) return;
      const { pack, data } = checkRes;
      const tableInfo = data.tableInfo || {};
      const cardFormInfo = data.cardFormInfo || {};
      const extPara = { pickOrder, btnKey, orderType: '出库' };

      const { id, wh_id } = this.props;
      const newPack = { ...cardFormInfo };
      newPack.id = id;
      newPack.wh_id = wh_id;
      newPack.stock_list = [...tableInfo];

      extPara.url = '/Warehouse/WmsBill/outHandle';
      extPara.pageType = pageType;
      if (isCheck) {
        return newPack;
      }
      this.sendReq(pageType, newPack, true, callback, extPara);
    };

    // 移库单保存
    moveWMSLoad = (parms, isCheck = false) => {
      const { pageType } = parms;
      const { btnKey, value, validateInfo, otherProps, callback, pickOrder, isPreLoad = false } = parms;
      // throughDate 为透传信息 可能没有
      if (!this.validateCheck(validateInfo)) return false;
      const checkRes = this.buildPack(pageType, value, otherProps, false);
      if (checkRes === false) return;
      const checkFeeInfo = this.checkFeeInfo(value, otherProps);
      if (checkFeeInfo === false) return;
      const { pack, data } = checkRes;
      const tableInfo = data.tableInfo || {};
      const cardFormInfo = data.cardFormInfo || {};
      const extPara = { pickOrder, btnKey, orderType: '移库' };

      const { id, wh_id } = this.props;
      const newPack = { ...cardFormInfo };
      newPack.id = id;
      newPack.wh_id = wh_id;
      newPack.op_time = moment().format('YYYY-MM-DD HH:mm:ss');
      // newPack.stock_list = [...tableInfo]
      const notPass = tableInfo.some(item => {
        // const reg = new RegExp(NUMBER)
        // if (item.move_wh_count && (isNaN(+item.move_wh_count) || !reg.test(+item.move_wh_count))) {
        //   showInfo(ERROR, '转移数量请输入正整数')
        //   return true
        // }
        if (+item.move_wh_count > +item.remain_count) {
          new PopUp(ShowInfo, {
            isShow: true,
            type: ERROR,
            title: '操作提醒',
            buttons: [
              {
                name: '确定',
                btnType: 'primary',
                cb: dialog => dialog.handleHide(),
              },
            ],
            content: '移库数量不能大于现有库存量',
          }).show();
          return true;
        }
        return false;
      });
      if (notPass) return false;
      newPack.stock_list = tableInfo.map(item => ({
        ...item,
        re_location_id: _.get(item, 're_location_id[0].id') || item.re_location_id,
      }));

      extPara.url = 'Warehouse/WmsBill/moveHandle';
      extPara.pageType = pageType;
      if (isCheck) {
        return newPack;
      }
      this.sendReq(pageType, newPack, true, callback, extPara);
    };

    moveStockLoad = parms => {
      const { pageType, btnKey, value, validateInfo, opType, callback, pickOrder } = parms;
      if (!this.validateCheck(validateInfo)) return false;
      const submitColumn = config[pageType].submitData;
      const data = this.formateData(value, submitColumn, undefined, pageType);
      if (data === false) return false;
      const tableData = data.tableInfo;
      const { cardFormInfo } = data;
      const operationType = opType === 'moveStock';

      const pack = {
        op_type: opType,
        od_link_ids: tableData.map(v => v.od_link_id),
        stock_point: cardFormInfo.stock_point,
        remark: cardFormInfo.remark,
        move_stock_t: cardFormInfo.move_stock_t,
      };

      if (!this.checkEmpty(pack[config[pageType].listKey], pageType)) return false;
      const failedDetail = [];
      if (failedDetail.length) {
        return opTips({
          resData: {
            errno: -100,
            res: {
              failed_detail: failedDetail,
            },
          },
          opType: operationType,
        });
      }
      this.sendReq(pageType, pack, true, callback, { pickOrder, btnKey, pageType: operationType });
    };
    manualSchedule = params => {
      const { pageType, btnKey, value, validateInfo, opType, callback, pickOrder } = params;
      if (!this.validateCheck(validateInfo)) return false;
      const listData = value.tableInfo.list;
      const companyId = {};
      listData.forEach(item => {
        if (item.com_id && !+item.pickup_tr_team_id) {
          companyId[item.com_id] = 1;
        }
      });
      if (Object.keys(companyId).length > 1) {
        showInfo(ERROR, '一次只能操作一个网点的数据！');
        return;
      }
      const submitColumn = config[pageType].submitData;
      const data = this.formateData(value, submitColumn, undefined, pageType);
      if (data === false) return false;
      const tableData = data.tableInfo;
      const { old_car_batch, car_batch, ...others } = data.cardFormInfo;
      const route_custom = _.get(value.cardInfo, 'route_line.otherProps.defaultSelected.0.line_nodes', []).map(i => ({
        company_id: i.company_id,
        company_name: i.company_name,
      }));
      const pack = {
        bsc_info: {
          ...others,
          route_custom,
        },
        old_car_batch,
        car_batch,
      };
      const submit = ids => {
        pack.od_list = ids;
        pickOrder.setRightButtonLoadingStatus(btnKey);
        postJAVA('/cmm-batch/japi/batch/createForDispatch', pack).then(res => {
          pickOrder.setRightButtonLoadingStatus(btnKey, false);
          opTips({
            resData: res,
            opType: '调度',
            continueCallback: (sucIds, cb) => {
              cb && cb();
              submit(sucIds);
            },
            sucCallback: callback,
          });
        });
      };
      submit(tableData);
    };
    composeOrderLine = async params => {
      const { pickOrder, value } = params;
      const taskLoadRef = _.get(value, 'taskLoadRef');
      const orderArrList = _.get(value, 'orderArrList');
      const od_link_ids = orderArrList.map(item => item.od_link_id || '');
      if (od_link_ids.length < 1) {
        confirm(WARN, '订单数据为空');
        return;
      }
      pickOrder.setLoadingStatus(true);
      const { errno, res } = await post('/Order/Order/getOrderRoute', { od_link_ids });
      const { route = [], plan_arr_t, plan_truck_t } = res;

      if (errno !== 0 || route.length < 1) {
        pickOrder.setLoadingStatus(false);
        return;
      }
      // 如果车线只有1条则补充一条空车线
      if (route.length === 1) {
        route.push({ node_type: 7 });
      }
      if (route.length > 20) {
        pickOrder.setLoadingStatus(false);
        const confirmRes = await confirm(
          WARN,
          '订单收发货地址超过20个节点，系统只自动带入前20个地址',
          { confirm: '继续' },
          '操作确认',
        );
        if (confirmRes) {
          route.splice(20);
          pickOrder.setLoadingStatus(true);
        } else {
          return;
        }
      }

      // 调用1.0运单设置车线数据
      if (_.isFunction(taskLoadRef.importRouteCustom)) {
        setTimeout(async () => {
          await taskLoadRef.importRouteCustom({ route, plan_arr_t, plan_truck_t });
          pickOrder.setLoadingStatus(false);
        }, 0);
      }
    };
    // 订单货物带到运单货物中
    composeOrderGoods = async params => {
      const { rightList, taskLoadRef } = params;
      const od_link_ids = rightList.map(item => item.od_link_id || '');
      let goods = [];
      if (od_link_ids.length > 0) {
        const { errno, res } = await post('/Order/Order/getGoodsListGroupByName', { od_link_ids });
        if (errno !== 0 || res.goods.length < 1) {
          return;
        }
        goods = res.goods.map(item => ({ ...item, ...(item.unit_p || {}) }));
      }

      // 调用1.0运单增加货物数据
      if (_.isFunction(taskLoadRef.importGoods)) {
        taskLoadRef.importGoods(goods);
        taskLoadRef.changeTemperatureRequirements?.();
      }
    };
    // 订单货物带到运单货物中（前端）
    composeOrderGoodsByFrontend = async params => {
      const { rightList, taskLoadRef } = params;
      const { uniqueGoodsKey } = config;
      const { weightUnitIsT } = taskLoadRef.getStore();
      // 一拆多（如订单包含多个货物）
      const flatList = _.flatMap(rightList, item =>
        item.g_name.map((v, i) => {
          const splitInfo = item.od_link_split_info || item.stock_goods;
          const isFrontendSplit = item.od_link_split_info;
          const { g_n, g_num, g_volume, g_weight, g_suit } = splitInfo || item;
          // 从挑单右侧列表中取数据
          const dangerFields = Object.keys(danger_field_key_map).reduce(
            (o, k) => ({ ...o, [k]: item?.[danger_field_key_map[k]]?.[i] }),
            {},
          );

          return {
            code: item.g_code?.[i] ?? '', // 货物代码
            name: item.g_name?.[i] ?? '', // 货物名称
            special: [item.g_special?.[i] ?? ''], // 特殊货物,
            pkg_service: item.g_pkg_service?.[i] ?? '', // 包装服务,
            price_unit: item.g_price_unit?.[i] ?? '', // 单价单位
            weight_per_num: item.g_weight_per_num?.[i] ?? 0, // 单件重量
            count: item.g_count?.[i] ?? 0, // 数量
            count_per_num: item.g_count_per_num?.[i] ?? 0, // 单件数量
            num_per_tray: item.g_num_per_tray?.[i] ?? 0, // 单托件数
            weight: isFrontendSplit && weightUnitIsT ? switchWeight(g_weight?.[i] ?? 0, true) : g_weight?.[i] ?? 0, // 重量
            num: splitInfo ? g_num?.[i] ?? 0 : g_n?.[i] ?? 0, // 件数
            volume: g_volume?.[i] ?? 0, // 体积
            length: item.g_length?.[i] ?? 0, // 长
            width: item.g_width?.[i] ?? 0, // 宽
            high: item.g_high?.[i] ?? 0, // 高
            cat: item.g_cat?.[i] ?? '', // 货物类型
            pkg: item.g_pkg?.[i] ?? '', // 包装，
            volume_per_num: item.g_volume_per_num?.[i] ?? 0, // 单件体积
            suit_unit_price: item.g_suit_unit_price?.[i] ?? 0, // 元/套
            ...item.g_unit_price_disp[i], // 单价unit_p + 单位unit
            spec: item.g_spec?.[i] ?? '', // 规格
            model: item.g_model?.[i] ?? '', // 型号
            pkg_service_check: item.g_pkg_service_check?.[i] ?? '', // 包装服务
            pkg_service_disp: item.g_pkg_service_disp?.[i] ?? '', // 包装服务
            suit: g_suit?.[i] ?? 0, // 套数
            practical_w: item.g_practical_w?.[i] ?? 0, // 货物实际重量
            practical_v: item.g_practical_v?.[i] ?? 0, // 货物实际体积
            weight_ext: item.g_weight_ext?.[i] ?? 0, // 重量
            volume_ext: item.g_volume_ext?.[i] ?? 0, // 体积
            weight_unit_price: item.g_weight_unit_price?.[i] ?? 0, // 重量单价
            volume_unit_price: item.g_volume_unit_price?.[i] ?? 0, // 体积单价
            tray_count: item.g_tray_count?.[i] ?? 0, // 托数
            subtotal_price: item.g_subtotal_price?.[i] ?? 0, // 运费小计
            ...dangerFields, // 危化货物字段
          };
        }),
      );
      // 分组（按照uniqueGoodsKey分组货物）
      const group = _.groupBy(flatList, item => {
        return uniqueGoodsKey.map(key => JSON.stringify(item[key] || 0)).join('_');
      });
      const goods = Object.values(group)
        .map(item =>
          // 合并托数，件数，重量，体积，套数，运费小计，数量
          item.reduce((a, b) => ({
            ...a,
            tray_count: bigComputed([+a.tray_count, +b.tray_count], 'plus'),
            num: bigComputed([+a.num, +b.num], 'plus'),
            volume: bigComputed([+a.volume, +b.volume], 'plus'),
            weight: bigComputed([+a.weight, +b.weight], 'plus'),
            suit: bigComputed([+a.suit, +b.suit], 'plus'),
            subtotal_price: _.round(bigComputed([+a.subtotal_price, +b.subtotal_price], 'plus'), 2),
            count: bigComputed([+a.count, +b.count], 'plus'),
          })),
        )
        // 过滤件数、重量、体积任一有值的
        .filter(item => item.num > 0 || item.volume > 0 || item.weight > 0 || item.suit > 0);
      // 初始化时延迟调用货物联动
      if (!this.firstImportGoodsOnce) {
        setTimeout(() => {
          if (_.isFunction(taskLoadRef.importGoods)) {
            taskLoadRef.importGoods(goods);
            taskLoadRef.changeTemperatureRequirements?.();
          }
        }, 1800);
        this.firstImportGoodsOnce = true;
        return;
      }
      // 调用1.0运单增加货物数据
      if (_.isFunction(taskLoadRef.importGoods)) {
        taskLoadRef.importGoods(goods);
        taskLoadRef.changeTemperatureRequirements?.();
        taskLoadRef.setContractorAttachment?.();
      }
    };
    render() {
      return <ComposedComponent {...this.props} {...this.state} />;
    }
  };
export default BiFormateData;
