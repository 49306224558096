import React from 'react';
import PropTypes from 'prop-types';
import { ModalDialog, Flex, CheckBox, Icon, Button, PopUp } from 'components';
import { fetchApi, showInfo, alert, queryPoling, opTips, sequentially, post } from 'utils';
import QRCode from 'qrcode';
import { ERROR, WARN } from 'constants';
import _ from 'lodash';
import wxPay from '../../../images/scanPay/wx-pay.png';
import aliPay from '../../../images/scanPay/ali-pay.png';
import paying from '../../../images/scanPay/paying.gif';
import { prefixCls } from './index.scss';

const optionsMap = {
  pay_billing: '现付',
  cashreturn: '现返',
  co_delivery: '代收货款',
  co_delivery_receipt: '代收货款',
  move_pay_billing: '现付异动',
  move_cashreturn: '现返异动',
  pay_arrival: '到付',
  move_pay_arrival: '到付异动',
  move_co_delivery: '货款异动',
  move_co_delivery_receipt: '货款异动',
};
const optionsOrder = [
  'pay_billing',
  'cashreturn',
  'pay_arrival',
  'co_delivery',
  'co_delivery_receipt',
  'move_pay_billing',
  'move_cashreturn',
  'move_pay_arrival',
  'move_co_delivery',
  'move_co_delivery_receipt',
];
const FeeLabel = ({ text, fee }) => (
  <Flex className="fee-label" justify="between">
    <span className="fee-text">{text}</span>
    <span className="fee-value">￥{fee}</span>
  </Flex>
);
const PAY_STATE = {
  1: '支付中',
  0: '支付成功',
  5: '未开始',
};
class ScanPaySlider extends React.Component {
  static propTypes = {
    ids: PropTypes.array,
    rate: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    options: PropTypes.array,
    details: PropTypes.array,
    optionEnum: PropTypes.array,
    ch_record_id: PropTypes.string,
    callback: PropTypes.func,
    close: PropTypes.func,
    receiptComAccount: PropTypes.string,
    receiptAccounts: PropTypes.object,
    payServiceAmounts: PropTypes.object,
    payServiceRates: PropTypes.object,
  };
  constructor(props) {
    super(props);
    const { options } = props;
    const checked = {};
    Object.keys(props.options || {}).forEach(b => {
      const c = +options[b];
      const dir = (this.optionsDir && this.optionsDir[b]) || 1;
      if (c * dir >= 0) {
        checked[b] = true;
      }
    }, 0);
    this.state = {
      options: this.initOptions(options, props.optionEnum), // 费用合集
      rate: props.rate || 0, // 手续费率
      checked, // 选中的费用
      scanUrl: '',
      QRCodeDataURL: '',
      feeTotal: 0, // 费用合计
      rateFee: 0, // 手续费
      payState: 0,
      payTotal: 0,
      receiptMoney: 0,
      errmsg: '',
      closeTime: 3,
      ch_record_id: props.ch_record_id,
      showRateFee:
        window.company_setting.handling_fee_payer && +window.company_setting.handling_fee_payer.checked === 1,
    };
    const req = {
      ch_record_id: props.ch_record_id,
    };
    // eslint-disable-next-line new-cap
    this.query = new queryPoling({
      url: '/Trade/QrcodePay/getPayStatus',
      req,
      time: 1000 * 3,
      checkRes: this.queryResFormat,
    });
    this.post = sequentially(post);
  }
  initOptions = (options, optionEnum) => {
    if (optionEnum) {
      this.optionsMap = {};
      this.optionsDir = {};
      optionEnum.forEach(item => {
        this.optionsMap[item.expense] = item.name;
        this.optionsDir[item.expense] = +item.direction === 0 ? 1 : -1;
      });
    } else {
      this.optionsMap = optionsMap;
    }
    this.optionsOrder = optionsOrder.filter(key => options[key]);
    return {
      ...options,
    };
  };
  componentDidMount() {
    this.set({ checked: this.state.checked });
    this.query.run();
    this.scanFocus();
    this.overTimeInit();
    if (window.whosyourdaddy) {
      const _self = this;
      window.scan = _self;
    }
  }
  componentWillUnmount() {
    this.query.abort();
    if (this.overTimer) {
      clearTimeout(this.overTimer);
    }
    window.scan = null;
    this.sucTimer && clearInterval(this.sucTimer);
  }
  overTimeInit = () => {
    this.overTimer = setTimeout(() => {
      this.setState({
        payState: 99,
      });
      this.query.abort();
    }, 1000 * 60 * 10);
  };
  refresh = () => {
    this.set({
      payState: 0,
      checked: this.state.options,
      closeTime: 3,
    });
    this.sucTimer && clearInterval(this.sucTimer);
    this.query.run();
  };
  // 后端返回的错误码无规律可寻，格式化下方便处理
  formatErrno = n => {
    let errno;
    switch (n) {
      case 5: // 未扫码
        errno = 0;
        break;
      case 1: // 支付中
        if (this.overTimer) {
          clearTimeout(this.overTimer);
        }
        errno = 10;
        break;
      case 0: // 支付成功
        errno = 21;
        break;
      default:
        // 支付失败
        errno = 31;
        break;
    }
    return errno;
  };
  queryResFormat = res => {
    if (res.errno === 18 || res.errno === -23 || this.state.payState > 10) {
      return false;
    }
    // eslint-disable-next-line prefer-const
    let { ch_record_id: id, pay_options: options, options_detail: optionEnum } = res.res || {};
    if (id && this.state.ch_record_id !== id) {
      this.setState({
        ch_record_id: id,
      });
      this.query.req = {
        ch_record_id: id,
      };
    }
    if (options) {
      if (!optionEnum) {
        optionEnum = this.props.optionEnum;
      }
      options = this.initOptions(options, optionEnum);
      const { checked } = this.state;
      Object.keys(checked).forEach(key => {
        if (!options[key]) {
          checked[key] = false;
        }
      });
      this.set({
        options,
        checked,
      });
    }
    const errno = this.formatErrno(res.errno);
    const isOk = errno > 20;
    this.scanFocus();
    // 成功，执行倒计时
    if (errno > 10 && errno < 30) {
      this.sucClose();
    }
    if (this.state.payState !== errno) {
      this.setState({
        payState: errno,
        errmsg: res.errmsg,
      });
    }
    return isOk;
  };
  sucClose = () => {
    const sucFunc = () => {
      let { closeTime } = this.state;
      if (closeTime > 0) {
        closeTime--;
        this.setState({
          closeTime,
        });
      } else {
        clearInterval(this.sucTimer);
        this.handleHide();
      }
    };
    this.sucTimer = setInterval(sucFunc, 1000);
  };
  scanFocus = () => {
    this.scaninput && this.scaninput.focus();
  };
  toPay = n => {
    const code = n;
    this.scanInputLastTime = null;
    this.scaninput.value = '';
    const req = this.initPayParam();
    req.authcode = code;
    fetchApi('Trade/QrcodePay/checkPayType', {
      method: 'POST',
      body: {
        req,
      },
    }).then(res => {
      if (res.errno !== 0 && res.errno !== -23) {
        this.setState({
          payState: 31,
          errmsg: res.errmsg,
        });
        this.query.abort();
      }
    });
  };
  initPayParam = (checked = this.state.checked) => {
    const { options } = this.props;
    const { ch_record_id: id } = this.state;
    const checkedKeys = Object.keys(checked).filter(key => checked[key]);
    // const payDetails = details.filter(item => checked[item.expense])
    const payOptions = {};
    checkedKeys.forEach(key => {
      payOptions[key] = options[key];
    });
    const params = {
      // ids,
      pay_options: payOptions,
      account_type: 0,
      ch_record_id: id,
      // pay_details: payDetails,
    };
    return params;
  };
  crateQrCode = checked => {
    const { origin } = window.location;
    // const origin = 'http://w-gamma-1000.chemanman.com'
    const pathname = '/api/Trade/QrcodePay/checkPayType';
    const params = JSON.stringify(this.initPayParam(checked));
    return QRCode.toDataURL(`${origin}${pathname}?req=${params}`, { margin: 0 });
  };
  set = async upd => {
    let updState = upd;
    // 改变支付费用项，需重新计算总费用和生成二维码
    if (upd.checked) {
      const options = this.state.options || {};
      const feeTotal = Object.keys(upd.checked).reduce((a, b) => {
        const c = upd.checked[b] ? +options[b] : 0;
        const dir = (this.optionsDir && this.optionsDir[b]) || 1;
        return (+a || 0) + c * dir;
      }, 0);
      if (feeTotal <= 0) {
        showInfo(WARN, '无法取消，取消后费用合计<=0无法收款');
        updState = { ...this.state };
      } else {
        const { payServiceAmounts, payServiceRates } = this.props;
        updState.payTotal = feeTotal;
        updState.feeTotal = feeTotal;
        if (payServiceAmounts && payServiceRates) {
          const checked = Object.keys(upd.checked).filter(key => upd.checked[key]);
          const unearnedRates = checked.map(key => +payServiceRates[`${key}_unearned`] || 0);
          const serviceRates = checked.map(key => +payServiceRates[`${key}_service`] || 0);
          const serviceRatesUnits = checked.map(key => payServiceRates[`${key}_service_unit`] || 0);
          const unearnedRateUnits = checked.map(key => payServiceRates[`${key}_unearned_unit`] || 0);
          updState.receiptMoney = +checked
            .reduce((pre, key) => pre + (+payServiceAmounts[`${key}_self`] || 0), 0)
            .toFixed(2);
          updState.newRate = true;
          updState.unearnedRate = Array.from(new Set(unearnedRates)).length > 1 ? null : unearnedRates[0];
          updState.serviceRate = Array.from(new Set(serviceRates)).length > 1 ? null : serviceRates[0];

          updState.serviceRateUnit = Array.from(new Set(serviceRatesUnits)).length > 1 ? null : serviceRatesUnits[0];

          updState.unearnedRateUnit = Array.from(new Set(unearnedRateUnits)).length > 1 ? null : unearnedRateUnits[0];

          updState.unearnedFee = +checked
            .reduce((pre, key) => pre + (+payServiceAmounts[`${key}_unearned`] || 0), 0)
            .toFixed(2);
          updState.serviceFee = +checked
            .reduce((pre, key) => pre + (+payServiceAmounts[`${key}_service`] || 0), 0)
            .toFixed(2);
          updState.payTotal = +(feeTotal + updState.unearnedFee).toFixed(2);
        } else {
          const rate = this.state.rate || 0;
          updState.rateFee = feeTotal * rate;
          updState.receiptMoney = +feeTotal.toFixed(2) - +updState.rateFee.toFixed(2);
        }
        updState.QRCodeDataURL = await this.crateQrCode(upd.checked);
      }
    }
    this.setState(updState);
  };

  onChange = key => isChecked => {
    let { checked } = this.state;
    checked = {
      ...checked,
      [key]: isChecked,
    };
    this.set({
      checked,
    });
  };
  // 复位scan input
  initScanVar = () => {
    this.scanInputLastTime = null;
    this.scaninput.value = '';
  };
  // 处理扫码枪输入，根据输入间隔判断是否是扫码枪
  onScanInput = e => {
    const lastTime = this.scanInputLastTime;
    const nextTime = new Date().getTime();
    const { value } = e.target;
    console.log(`时间间隔:${nextTime - lastTime}, 输入值:${e.target.value}`);
    if (lastTime && nextTime - lastTime <= 120) {
      // time1 执行扫码支付
      this.timer1 && clearTimeout(this.timer1);
      // time2 执行复位相关变量
      this.timer1 = setTimeout(() => this.toPay(value), 150);
      this.scanInputLastTime = nextTime;
    } else if (lastTime) {
      this.scaninput.value = '';
      this.scanInputLastTime = null;
    } else {
      this.scanInputLastTime = nextTime;
    }
  };

  getAccountsTip = () => {
    const { receiptAccounts } = this.props;
    const isWallet = receiptAccounts.collection_account !== '平台账户';
    return (
      <div>
        {isWallet && receiptAccounts.billing_receipt_com && (
          <div>现付现返及异动收款账户：{receiptAccounts.billing_receipt_com}</div>
        )}
        {isWallet && receiptAccounts.arr_receipt_com && (
          <div>到付及异动收款账户：{receiptAccounts.arr_receipt_com}</div>
        )}
        {isWallet && receiptAccounts.co_delivery_receipt && (
          <div>货款及异动收款账户：{receiptAccounts.co_delivery_receipt}</div>
        )}
        {!isWallet && <div>收款账户：本网点平台账户</div>}
        *手续费预收和扣费账户，请前往系统设置查看
      </div>
    );
  };
  renderSuc = () => {
    const { closeTime, payTotal } = this.state;
    const total = (+payTotal).toFixed(2);
    return (
      <Flex dir="column" className="pay-suc" align="center" justify="center" height={220}>
        <Icon iconType="icon-dui" />
        <span className="pay-tip">已收款￥{total}</span>
        <span className="close-tip"> {closeTime}秒后关闭此页面 </span>
      </Flex>
    );
  };
  renderFail = () => {
    const { errmsg } = this.state;
    return (
      <Flex dir="column" className="pay-fail" align="center" justify="center" height={220}>
        <Icon iconType="icon-gantanhao" />
        <span className="pay-tip">支付失败</span>
        <span className="errmsg">{errmsg}</span>
        <Button onClick={this.refresh}>刷新重试</Button>
      </Flex>
    );
  };
  renderOverTime = () => (
    <Flex dir="column" className="pay-fail" align="center" justify="center" height={220}>
      <Icon iconType="icon-gantanhao" />
      <span className="pay-tip">支付失败</span>
      <span className="errmsg">支付超时，请关闭此页面重新支付</span>
    </Flex>
  );
  renderPaying = () => (
    <Flex dir="column" className="paying" align="center" justify="center" height={220}>
      <img src={paying} />
      <span className="pay-tip">支付中...</span>
    </Flex>
  );
  renderScan = () => (
    <Flex className="scan" align="stretch" height={220}>
      <Flex dir="column" align="center" className="qr-code">
        <img id="qrcode" src={this.state.QRCodeDataURL} />
        <span>请让客户扫此收款码</span>
      </Flex>
      {!this.state.source === 6 && (
        <Flex dir="column" align="center" className="split">
          <div className="line-1" />
          <span>或</span>
          <div className="line-2" />
        </Flex>
      )}
      {!this.state.source === 6 && (
        <Flex dir="column" align="center" className="scan-gun">
          <div id="scan-gun" />
          <span>请扫描客户付款码</span>
        </Flex>
      )}
    </Flex>
  );
  renderContent = () => {
    const {
      options,
      checked,
      feeTotal,
      payTotal,
      rateFee,
      rate,
      payState,
      receiptMoney,
      unearnedRate,
      unearnedRateUnit,
      serviceRate,
      serviceRateUnit,
      unearnedFee,
      serviceFee,
      newRate,
    } = this.state;
    const { receiptComAccount, receiptAccounts } = this.props;
    let content;
    // 待支付
    if (+payState < 10) {
      content = this.renderScan();
    } else if (+payState < 20) {
      // 支付中
      content = this.renderPaying();
    } else if (+payState < 30) {
      // 支付成功
      content = this.renderSuc();
    } else if (+payState === 99) {
      content = this.renderOverTime();
    } else {
      content = this.renderFail();
    }
    return (
      <Flex dir="column" align="stretch">
        <Flex className="form" width="100%" dir="column">
          <Flex className="fee">
            <label className="label">
              <Icon iconType="icon-zhangdan" />
              &nbsp;费用项：
            </label>
            <Flex className="fee-items" wrap align="center">
              {this.optionsOrder.map(key => (
                <CheckBox
                  key={key}
                  name={key}
                  disabled={+payState >= 10}
                  direction="right"
                  checked={checked[key]}
                  onChange={this.onChange(key)}
                  title={`${this.optionsMap[key]}：${options[key]}`}
                  label={<FeeLabel text={this.optionsMap[key]} fee={options[key]} />}
                />
              ))}
            </Flex>
          </Flex>
          <Flex align="center" className="fee-info row">
            <div className="row">
              <Flex className="fee-sum row" align="center">
                <label className="label">
                  <Icon iconType="icon-feiyongshuoming" />
                  &nbsp;费用合计：
                </label>
                <strong>￥{+feeTotal.toFixed(2)}</strong>
              </Flex>
              {!newRate && (
                <Flex className="fee-rate" align="center">
                  <label className="label">
                    <Icon iconType="icon-tibifeishuai" />
                    &nbsp;手续费<span className="rate-tip">(费率{rate * 100}%)：</span>
                  </label>
                  <strong>￥{+rateFee.toFixed(2)}</strong>
                </Flex>
              )}
              {newRate && !!unearnedFee && (
                <Flex className="fee-rate" align="center">
                  <label className="label">
                    <Icon iconType="icon-tibifeishuai" />
                    &nbsp;预收手续费
                    {unearnedRate && (
                      <span className="rate-tip">
                        (费率{unearnedRate}
                        {unearnedRateUnit})：
                      </span>
                    )}
                  </label>
                  <strong>￥{unearnedFee}</strong>
                </Flex>
              )}
              {newRate && !!serviceFee && (
                <Flex className="fee-rate" align="center">
                  <label className="label">
                    <Icon iconType="icon-tibifeishuai" />
                    &nbsp;入金手续费
                    {serviceRate && (
                      <span className="rate-tip">
                        (费率{serviceRate}
                        {serviceRateUnit})：
                      </span>
                    )}
                  </label>
                  <strong>￥{serviceFee}</strong>
                </Flex>
              )}
            </div>
            <div className="row">
              <Flex className="fee-total" align="baseline">
                <label className="label ar">本次收款：</label>
                <span>
                  <span className="unit">￥</span>
                  <span className="money">{+payTotal.toFixed(2)}</span>
                </span>
              </Flex>
              <Flex align="center">
                <label className="label ar">实际到账：</label>
                <span className="receipt-money">￥{+receiptMoney.toFixed(2)}</span>
              </Flex>
            </div>
          </Flex>
        </Flex>
        {content}
        <Flex align="center" justify="between" className="pays">
          <Flex align="center" className="support">
            <span>支持：</span>
            <img src={wxPay} title="微信支付" />
            <img src={aliPay} title="支付宝支付" />
            <input className="scaninput" onChange={this.onScanInput} ref={ref => (this.scaninput = ref)} />
          </Flex>
          {!!receiptAccounts && (
            <Flex align="center" className="receipt-com-account">
              收款账户信息：
              <Icon iconType="icon-help" placement="top" tips={this.getAccountsTip()} />
            </Flex>
          )}
          {!receiptAccounts && !!receiptComAccount && (
            <Flex align="center" className="receipt-com-account">
              将收款到：<span>{receiptComAccount}</span>
            </Flex>
          )}
        </Flex>
      </Flex>
    );
  };
  handleHide = () => {
    const { payState } = this.state;
    const isOk = payState > 10 && payState < 30;
    this.modelDialog && this.modelDialog.handleHide();
    this.props.callback(isOk);
    this.props.close && this.props.close();
  };

  renderBottom = () => [<Button onClick={this.handleHide}>关闭</Button>];
  render() {
    return (
      <div ref={ref => (this.wrap = ref)} onClick={this.scanFocus}>
        <ModalDialog
          classname={prefixCls}
          title="收款"
          // content={success ? successTip : content}
          content={this.renderContent()}
          bottom={this.renderBottom()}
          isShow
          closable
          autoFocus={false}
          maskClosable={false}
          close={this.handleHide}
          contentStyle={{ width: 690 }}
          ref={r => (this.modelDialog = r)}
        />
      </div>
    );
  }
}
const scanReceiptCore = (ids, that, btnKey, isWhere) =>
  new Promise(resolve => {
    const data = ids;
    const isCo = that && that.isCo;
    btnKey && that.disableMenuButton(btnKey);
    let isCallback;
    const callback = payRes => {
      btnKey && that.enableMenuButton(btnKey);
      if (!isCo && payRes && !isCallback) {
        that.handleRefreshData && that.handleRefreshData();
      }
      isCallback = true;
      resolve();
    };
    if (!data) return callback();
    if (!data.length) {
      showInfo(ERROR, '请选择要操作的数据');
      callback();
      return;
    }
    let pageType;
    const isOrderList = ['od_all', 'co_pre', 'co', 'pre_arrival', 'od_goods_arr', 'od_obsolete'];
    const { tab } = that.tableInfo || {};
    // 开单页
    if (isCo) {
      pageType = 1;
      // 运单详情页
    } else if (tab === '') {
      pageType = 2;
      // 运单列表页
    } else if (isOrderList.includes(tab)) {
      pageType = 3;
      // 收款列表页
    } else if (`${tab}`.startsWith('receipt_')) {
      pageType = 4;
      // 单票签收
    } else if (isWhere === 'single_sign') {
      pageType = 7;
      // 签收列表页
    } else if (`${tab}`.endsWith('_sign')) {
      pageType = 6;
      // 批量签收页
    } else if (isWhere === 'batch_sign') {
      pageType = 7;
    }
    fetchApi('Trade/QrcodePay/getPayoptions', {
      method: 'POST',
      body: {
        req: {
          ids: data,
          page_type: pageType,
        },
      },
    }).then(res => {
      btnKey && that.enableMenuButton(btnKey);
      const showScanModal = () => {
        const options = res.res.pay_options || {};
        if (!Object.keys(options).length) {
          return callback(false);
        }
        new PopUp(ScanPaySlider, {
          options: res.res.pay_options,
          // options: o,
          optionEnum: res.res.options_detail,
          payServiceRates: res.res.pay_service_rates,
          payServiceAmounts: res.res.pay_service_amounts,
          receiptAccounts: res.res.receipt_accounts,
          // receiptComAccount: '娇哥账户',
          receiptComAccount: res.res.receipt_com_account,
          details: res.res.pay_details,
          rate: res.res.pay_rates && res.res.pay_rates.wxqr_rate,
          ch_record_id: res.res.ch_record_id,
          ids: data,
          source: res.res.source,
          callback,
        }).show();
      };
      if (isCo && res.errno !== 0) {
        return callback(false);
      }
      const failedDetail = _.get(res, 'res.failed_detail', []);
      if (res.errno !== 0 && failedDetail.length === 0) {
        if (res.errno === 1000) {
          alert(ERROR, res.errmsg);
        } else {
          showInfo(ERROR, res.errmsg);
        }
        return callback(false);
      }
      opTips({
        resData: res,
        opType: '扫码收款',
        showSucTips: false,
        sucCallback: () => {
          showScanModal();
        },
        continueCallback: (sucId, cb) => {
          cb && cb();
          showScanModal();
        },
        ext: {
          failedCallback: () => {
            callback(false);
          },
        },
      });
    });
  });

export default scanReceiptCore;
