import { Amap, PopUp } from 'components';
import React, { useEffect, useState } from 'react';
import { handleAPIResponse, postJAVA, bem, showInfo } from 'utils';
import { ERROR } from 'constants';
import { Spin, Button, Tooltip } from '@gui/web-react';
import { prefixCls } from './index.scss';
import StdLine from '../StdLine';

function RenderButton({ loadError, openStdLineDialog, fetchStdLine }) {
  const cls = bem(prefixCls);
  return !loadError ? (
    <div className={cls('add')}>
      <Button type="primary" onClick={openStdLineDialog}>
        <i className="fn-icon fn-icon-file-add" />
        为该车线创建一条标准线路
      </Button>
    </div>
  ) : (
    <div className={cls('add')}>
      <Button type="primary" onClick={fetchStdLine}>
        <i className="fn-icon fn-icon-file-add" />
        请求标准线路失败，请重试
      </Button>
    </div>
  );
}

export default function RouteCard({ lineId, opType, lineNodes, onClose }) {
  const cls = bem(prefixCls);
  const [isLoading, setLoading] = useState(true);
  const [stdLineData, setStdLineData] = useState(null);
  const [loadDataError, setLoadDataError] = useState(false);
  const [mapInstance, setMapInstance] = useState(null);

  const fetchStdLine = () => {
    setLoading(true);
    handleAPIResponse(postJAVA('/cmm-basic/line/std_route/query', { line_id: lineId }))
      .then(res => {
        setLoadDataError(false);
        setStdLineData(res);
      })
      .catch(() => {
        setLoadDataError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchStdLine();
  }, [lineId]);

  useEffect(() => {
    // 画地图
    if (mapInstance && stdLineData) {
      drawRouteLine();
    }
    return () => {
      // 组件销毁的时候需要销毁地图
      if (mapInstance) {
        mapInstance?.destroy();
        setMapInstance(null);
      }
    };
  }, [mapInstance, stdLineData]);

  /**
   * 绘制单个marker
   * @param {*} pointData { lng, lat}
   * @returns marker实例
   */
  const drawMarker = (pointData = {}, config) => {
    if (!pointData) {
      return;
    }
    const {
      iconUrl = 'https://static.g7cdn.com/fe-cdn/bill-crose.png',
      size = 32,
      // 层级高德官方默认12
      zIndex = 12,
    } = config || {};
    const point = new window.AMap.LngLat(pointData.lng, pointData.lat);
    const marker = new window.AMap.Marker({
      position: point,
      icon: new window.AMap.Icon({
        image: iconUrl,
        size: [size, size],
        imageSize: [size, size],
      }),
      angle: pointData.direction,
      offset: new window.AMap.Pixel(-(size / 2), -(size / 2)),
      zIndex,
    });
    mapInstance.add(marker);
    return marker;
  };

  // 在地图上绘制车线，起点，途经点
  const drawRouteLine = () => {
    // 先清除
    mapInstance?.clearMap();
    // 画线
    const { points, map_nodes } = stdLineData || {};
    const pointsArr = (points || []).map(({ lng, lat }) => new window.AMap.LngLat(lng, lat));
    const crossLineOverlay = new window.AMap.Polyline({
      path: pointsArr,
      strokeColor: '#23CF3B',
      strokeWeight: 7,
      showDir: true,
    });
    mapInstance.add(crossLineOverlay);
    // 起始点和途经点
    if (Array.isArray(map_nodes)) {
      for (let i = 0; i < map_nodes.length; i++) {
        // 只画车线节点
        if (map_nodes[i].is_line_node) {
          let iconUrl = 'https://static.g7cdn.com/fe-cdn/waybill/img/bill-crose.png';
          if (i === 0) {
            iconUrl = 'https://static.g7cdn.com/fe-cdn/waybill/img/bill-put.png';
          } else if (i === map_nodes.length - 1) {
            iconUrl = 'https://static.g7cdn.com/fe-cdn/waybill/img/bill-lay-down.png';
          }
          drawMarker(map_nodes[i], {
            iconUrl,
            // 轨迹上的层级放低，要不然会高于marker
            zIndex: 1,
          });
        }
      }
    }
    // 全部画完后视野缩放
    mapInstance.setFitView();
  };

  const onMapLoaded = map => {
    // 需求要求禁止缩放地图
    map.setStatus({ scrollWheel: false });
    setMapInstance(map);
  };

  const openStdLineDialog = () => {
    if (lineNodes?.filter(item => +item.node_type !== 7).length > 0) {
      showInfo(ERROR, '节点类型请选择高德地址');
      return;
    }
    if (lineNodes?.length > 16) {
      showInfo(ERROR, '车线节点超过16个无法创建标准线路');
      return;
    }
    let dialog = null;
    const onCloseStdLineDialog = ({ type }) => {
      dialog?.close();
      onClose({ type });
    };
    dialog = new PopUp(<StdLine opType={opType} lineId={lineId} onClose={onCloseStdLineDialog} />, {});
    dialog.show();
  };

  const infoList = [
    {
      label: '标准线路名称',
      key: 'name',
    },
    {
      label: '标准线路里程',
      key: 'total_distance',
      render(mileage) {
        return `${(mileage / 1000).toFixed(2)}公里`;
      },
    },
    {
      label: '途径',
      key: 'path',
      extKey: 'full_path',
      render(path, fullPath) {
        const completePath = fullPath || path;
        return (
          <Tooltip position="top" content={completePath}>
            <span className={cls('path')}>{path}</span>
          </Tooltip>
        );
      },
    },
    {
      label: '创建人',
      key: 'create_user_name',
    },
    {
      label: '创建时间',
      key: 'create_time',
    },
    {
      label: '修改人',
      key: 'update_user_name',
    },
    {
      label: '修改时间',
      key: 'update_time',
    },
  ];

  return (
    <Spin loading={isLoading} block>
      <div className={cls('wrap')}>
        {!stdLineData ? (
          <RenderButton loadError={loadDataError} openStdLineDialog={openStdLineDialog} fetchStdLine={fetchStdLine} />
        ) : (
          <div className={cls('view')}>
            <div className={cls('info')}>
              <ul className={cls('list')}>
                {infoList.map(item =>
                  item.render ? (
                    <li className={cls('item')}>
                      <div className={cls('label')}>{item.label}</div>
                      <div className={cls('value')}>
                        {item.render(stdLineData[item.key], item.extKey ? stdLineData[item.extKey] : '')}
                      </div>
                    </li>
                  ) : (
                    <li className={cls('item')}>
                      <div className={cls('label')}>{item.label}</div>
                      <div className={cls('value')}>{stdLineData[item.key]}</div>
                    </li>
                  ),
                )}
              </ul>
            </div>
            <div className={cls('map')}>
              <Amap.MapShow width="100%" height="100%" onMapLoaded={onMapLoaded} />
              <Button className={cls('map__btn')} onClick={openStdLineDialog} type="primary">
                查看线路
              </Button>
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
}
