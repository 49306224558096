import React, { useImperativeHandle, useRef, useState, forwardRef } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { CardBox, Select } from 'components';
import { validateFieldsOf } from 'utils';
import BaseInfo from './Components/BaseInfo';
import DataFilter from './Components/DataFilter';
import ComposeRule from './Components/ComposeRule';
import { MATCH_TYPE_ENUM } from './constant';

const defaultMatchType = '1';
let ruleFilterStore = { matchType: defaultMatchType };

const RuleFilter = (props, ref) => {
  const { data, onSuccess, onSubmit } = props;
  const ruleFilterRef = useRef();
  const [initialData] = useState(data?.data || { type: 'order' });
  const [enums, setEnums] = useState(data?.enums?.[initialData.type] || {});
  const handleSetRuleFilterData = val => {
    if ('type' in val) {
      setEnums(data?.enums?.[val.type]);
    }
    ruleFilterStore = { ...ruleFilterStore, ...val };
  };
  const handleSubmit = async () => {
    const regResult = await validateFieldsOf(ruleFilterRef?.current);
    if (!regResult) return false;

    if (_.isFunction(onSubmit)) {
      onSubmit?.(ruleFilterStore);
      return;
    }
    if (_.isFunction(onSuccess)) {
      onSuccess('ok');
    }
  };

  useImperativeHandle(ref, () => ({
    submit: handleSubmit,
  }));

  const DataFilterHeader = () => {
    const [matchType, setMatchType] = useState(initialData?.match_type || defaultMatchType);
    const handleMatchTypeChange = val => {
      setMatchType(val?.type);
      ruleFilterStore.matchType = val?.type;
    };
    return (
      <div style={{ float: 'right', lineHeight: '32px', display: 'flex', marginRight: 10 }}>
        符合以下
        <Select
          value={matchType}
          data={MATCH_TYPE_ENUM}
          map={false}
          compare="type"
          style={{ border: '1px solid #22242633' }}
          inputWrapStyle={{ width: 50 }}
          onChange={handleMatchTypeChange}
        />
        条件
      </div>
    );
  };
  return (
    <div ref={ruleFilterRef}>
      <CardBox title="基础信息">
        <BaseInfo initialData={initialData} setRuleFilterData={handleSetRuleFilterData} />
      </CardBox>
      <CardBox title="筛选条件" header={DataFilterHeader()}>
        <DataFilter
          initialData={initialData}
          enums={enums}
          enum_api={data?.enum_api}
          setRuleFilterData={handleSetRuleFilterData}
        />
      </CardBox>
      <CardBox title="合并规则">
        <ComposeRule initialData={initialData} enums={enums} setRuleFilterData={handleSetRuleFilterData} />
      </CardBox>
    </div>
  );
};
RuleFilter.propTypes = {
  data: PropTypes.object,
  onSuccess: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default forwardRef(RuleFilter);
