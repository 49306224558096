exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".fee-report .fn-icon-help{color:#3c8bdc;margin-top:6px;margin-left:3px;font-weight:400;font-size:14px}.fee-report .fn-icon-calculate{color:#3c8bdc;margin-top:8px;margin-left:3px;font-weight:400;font-size:16px}.fee-report .fn-radio__w{padding-top:2px}.fee-report .remark .fn-input-pure-wrap{height:78px}.fee-report .div-wrap .transform-fileupload-wrap{padding:0 0 10px}.fee-report .transform-fileupload:nth-child(n+1){margin-left:0}.fee-report__relation_info{margin-top:10px}.fee-report__relation_info .card-wrapper{width:100%}.fee-report__relation_info .fn-card-box__content{padding:10px}.fee-report__relation_info .relation-content,.fee-report__relation_info .relation-left{display:flex;justify-content:space-between;align-items:center}.fee-report__relation_info .link{color:#0097cf;cursor:pointer;margin-right:10px}.fee-report__relation_info .fn-input-pure-gps_miles{width:120px}.fee-report__relation_info .fn-icon-refresh{cursor:pointer}.fee-report__relation_info .fn-icon-refresh:hover{color:#3c8bdc}.fee-report__relation_info .time{font-size:10px;color:#aaa}#feeShareChange table{font-size:12px;color:#333;margin-top:15px}#feeShareChange table td,#feeShareChange table th{height:28px;text-align:center;border:1px solid #b9ccd7;padding:1px;width:94px}#feeShareChange table td .fn-input-pure-wrap,#feeShareChange table th .fn-input-pure-wrap{height:26px;width:100%;border:1px solid #d1d8e1;border-radius:2px}#feeShareChange table td .fn-input-pure-wrap input,#feeShareChange table th .fn-input-pure-wrap input{border:none!important;height:22px}#feeShareChange table td.required,#feeShareChange table th.required{color:red}", ""]);

// exports
exports.locals = {
	"prefixCls": "fee-report",
	"fee-report": "fee-report",
	"fn-icon-help": "fn-icon-help",
	"fn-icon-calculate": "fn-icon-calculate",
	"fn-radio__w": "fn-radio__w",
	"remark": "remark",
	"fn-input-pure-wrap": "fn-input-pure-wrap",
	"div-wrap": "div-wrap",
	"transform-fileupload-wrap": "transform-fileupload-wrap",
	"transform-fileupload": "transform-fileupload",
	"fee-report__relation_info": "fee-report__relation_info",
	"card-wrapper": "card-wrapper",
	"fn-card-box__content": "fn-card-box__content",
	"relation-content": "relation-content",
	"relation-left": "relation-left",
	"link": "link",
	"fn-input-pure-gps_miles": "fn-input-pure-gps_miles",
	"fn-icon-refresh": "fn-icon-refresh",
	"time": "time",
	"feeShareChange": "feeShareChange",
	"required": "required"
};